import { localize } from '../services/LocalizationService';
import { LocaleName } from 'src/types/LocaleName';
import LocalizationService from 'src/services/LocalizationService';
import { useAccountStore } from 'src/store/module-account';
import Bowser from 'bowser';
import useFilters from 'src/helpers/custom-hooks/useFilters';

export default class DateUtil {
    private static filters = useFilters();

    public static browserName = Bowser.getParser(window.navigator.userAgent).getBrowserName();

    public static get monthNames(): string[] {
        return [
            localize('Январь'),
            localize('Февраль'),
            localize('Март'),
            localize('Апрель'),
            localize('Май'),
            localize('Июнь'),
            localize('Июль'),
            localize('Август'),
            localize('Сентябрь'),
            localize('Октябрь'),
            localize('Ноябрь'),
            localize('Декабрь'),
        ];
    }

    public static get monthShortNames(): string[] {
        return [
            localize('Янв') + '.',
            localize('Февр') + '.',
            localize('Март'),
            localize('Апр') + '.',
            localize('Май'),
            localize('Июнь'),
            localize('Июль'),
            localize('Авг') + '.',
            localize('Сент') + '.',
            localize('Окт') + '.',
            localize('Нояб') + '.',
            localize('Дек') + '.',
        ];
    }

    /**
     * Дни недели, по умолчанию первый день недели - воскресенье
     * @param isFirstDayMonday - нужно первым днем вернуть понедельник
     */
    public static daysOfWeek(isFirstDayMonday: boolean = false): { day: string, shortDay: string }[] {
        const daysOfWeek: { day: string, shortDay: string }[] = [{
            shortDay: localize('Вс'),
            day: localize('Воскресенье'),
        }, {
            shortDay: localize('Пн'),
            day: localize('Понедельник'),
        }, {
            shortDay: localize('Вт'),
            day: localize('Вторник'),
        }, {
            shortDay: localize('Ср'),
            day: localize('Среда'),
        }, {
            shortDay: localize('Чт'),
            day: localize('Четверг'),
        }, {
            shortDay: localize('Пт'),
            day: localize('Пятница'),
        }, {
            shortDay: localize('Сб'),
            day: localize('Суббота'),
        }];

        if (isFirstDayMonday) {
            const firstDay = daysOfWeek.splice(0, 1)[0];
            daysOfWeek.push(firstDay);
        }

        return daysOfWeek;
    }

    public static validateYear(date: string): string {
        let yearDate = date.split('T')[0].split('-')[0];
        if (yearDate && yearDate === '0000') {
            yearDate = new Date().getFullYear().toString();
            const month = date.split('T')[0].split('-')[1];
            const day = date.split('T')[0].split('-')[2];
            const time = date.split('T')[1];
            date = `${yearDate}-${month}-${day}T${time}`;
        }
        return date;
    }

    // return 2021-03-07T00:00 from new Date()
    public static getFormatServerDateTime(date: Date, includeSeconds: boolean = false): string {
        // Прибавляем 1 так как в JS месяцы нумеруются с нуля
        const monthIndex = date.getMonth() + 1;
        const month = monthIndex < 10 ? '0' + monthIndex : monthIndex;
        const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return includeSeconds ? `${date.getFullYear()}-${month}-${day}T${hours}:${minutes}:${seconds}`
            : `${date.getFullYear()}-${month}-${day}T${hours}:${minutes}`;
    }

    // return 2021-03-07T00:00 07.03.2021 from 2021-03-07T__:__
    public static getGenerateTime(date: string): string {
        let arrTime: string[] | undefined = date.split('T')[1].toString().replace(/[^:\d]/g, '').split(':');
        if (arrTime && arrTime.indexOf('') > -1 && new Date(date.split('T')[0].toString())) {
            arrTime = arrTime.map((el: string) => {
                return el === '' ? '00' : el;
            });
            return date.split('T')[0] + 'T' + arrTime.join(':');
        }

        if (Number(arrTime[0]) > 23) {
            arrTime[0] = '00';
        }
        if (Number(arrTime[1]) > 59) {
            arrTime[1] = '00';
        }

        date = this.validateYear(date);

        return date.split('T')[0] + 'T' + arrTime.join(':');
    }

    // return 2021-03-07T00:00:00 07.03.2021 from 07.03.2021, 12:00
    public static getDateInterfaceToServer(date: string, lang: LocaleName): string {
        const arrayFullDate = date.split(',');
        const arrayDate = arrayFullDate[0].split('.');
        // Проверка на времмя
        let time = 'T00:00';
        if (arrayFullDate[1]) {
            let arrayTime = arrayFullDate[1].split(':');
            // Убираем пробелы
            arrayTime = arrayTime.map((el: string) => el.trim());
            const hour = arrayTime[0] ? arrayTime[0] : '00';
            const minuts = arrayTime[1] ? arrayTime[1] : '00';
            time = `T${hour}:${minuts}`;
        }
        // ODIN-11523 отключено это условие тк по факту формат в датапикере всегда русский
        // TODO починить английский формат дат в датапикере
        if (lang === LocaleName.EN && false) {
            return `${arrayDate[2]}-${arrayDate[0]}-${arrayDate[1]}${time}`;
        }
        return `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}${time}`;
    }

    // Смещение от UTC в минутах на текущем устройстве, если часовой пояс устройства
    // UTC+1, то вернёт 60
    public static getDeviceTimezoneOffset(): number {
        return this.getTimezoneOffset(new Date());
    }

    // Смещение от UTC в минутах, если часовой пояс параметра date UTC+1, то вернёт 60
    public static getTimezoneOffset(date: Date): number {
        // Стандартный метод возвращает сколько минут нужно прибавить, чтобы было время UTC, поэтому
        // для положительного смещения от UTC значения возвращаются отрицательные
        return -(date.getTimezoneOffset());
    }

    // return 08:45 from 2021-03-07T08:45:00
    public static getTime(isoString: Date | string): string {
        // Поскольку мы не передаем часовой пояс, время может быть интерпретировано в UTC и привестись к длкальному
        // часовому поясу. Чтобы этого избежать, нужно самостоятельно распарсить строку
        const date = typeof isoString !== 'string'
            ? isoString
            : DateUtil.parseIsoLocal(isoString);

        // Для времени в 24-часовом всегда указываем русскую локаль, так как в англоязычной локали 00:хх отображается как 24:хх
        return date.toLocaleTimeString(['ru'], {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
        });
    }

    private static parseIsoLocal(isoString: string): Date {
        const parts = isoString
            .split(/\D/)
            .map((value: string) => Number.parseInt(value));

        return new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], 0);
    }

    /**
     * Возвращает строку, часов склоненную в зависимости от значения объёма
     * @param hours - количество часов
     * @param includeNumber - нужно ли добавить цифру
     */
    public static getHoursString(hours: number | null | undefined, includeNumber: boolean = false): string | null {
        if (!hours) {
            return null;
        }
        return hours.cased(
            localize('час'),
            localize('часа'),
            localize('часов'),
            includeNumber);
    }

    /**
     * @param month Месяц в числовом формате 0 - 11 <=> январь - декабрь
     * @param year Год в котором проверять число месяцев
     * @returns Число дней в указанном месяце
     */
    public static daysInMonth(month: number, year: number): number {
        const nextMonth = month + 1;
        return new Date(year, nextMonth, 0).getDate();
    }

    /**
     * @param e - объект даты или строка
     * @returns Полное название месяца
     */
    public static getLongMonthName(e: Date | string): string {
        const date = (typeof e === 'string') ?  new Date(e) : e;
        return this.monthNames[date.getMonth()];
    }

    /**
     * Добавление к дате времени
     * @param date Дата в виде валидной строки или Date объекта
     * @param options Что нужно добавить
     * @returns новая дата с изменёнными значениями времени
     */
    public static add(date: string | Date, options: {
        milliseconds?: number;
        seconds?: number;
        minutes?: number;
        hours?: number;
        days?: number;
        months?: number;
        years?: number;
    }): Date {
        const dateFromArgument = new Date(date);
        if (options.milliseconds) {
            dateFromArgument.setMilliseconds(dateFromArgument.getMilliseconds() + options.milliseconds);
        }
        if (options.seconds) {
            dateFromArgument.setSeconds(dateFromArgument.getSeconds() + options.seconds);
        }
        if (options.minutes) {
            dateFromArgument.setMinutes(dateFromArgument.getMinutes() + options.minutes);
        }
        if (options.hours) {
            dateFromArgument.setHours(dateFromArgument.getHours() + options.hours);
        }
        if (options.days) {
            dateFromArgument.setDate(dateFromArgument.getDate() + options.days);
        }
        if (options.months) {
            const monthToSet = dateFromArgument.getMonth() + options.months;
            const dateDay = dateFromArgument.getDate();
            const lastMonthDate = this.daysInMonth(monthToSet, dateFromArgument.getFullYear());
            let dayToSet = dateDay;
            // Нужно уменьшить число устанавливаемых дней, если они выходят за рамки
            // количества дней месяца, иначе будет перескок через месяц
            // Например если перейти к февралю с датой 31, то окажемся в 3 или 2 марта
            if (lastMonthDate < dayToSet) {
                dayToSet = lastMonthDate;
            }
            dateFromArgument.setMonth(monthToSet, dayToSet);
        }
        if (options.years) {
            dateFromArgument.setFullYear(dateFromArgument.getFullYear() + options.years);
        }

        return dateFromArgument;
    }

    /**
     * Получить разницу между двумя датами
     * @param date1 Первый аргумент
     * @param date2 Второй аргумент
     * @returns Разница во времени между первым и вторым аргументом в разных единицах измерения
     */
    public static getDatesDiff(date1: string | Date, date2: string | Date): {
        /** Разница в миллисекундах */
        milliseconds: number;
        /** Разница в секундах */
        seconds: number;
        /** Разница в минутах */
        minutes: number;
        /** Разница в часах */
        hours: number;
        /** Разница в днях */
        days: number;
        /** Разница в месяцах */
        months: number;
        /** Разница в годах */
        years: number;
        /** Арифметический знак разницы */
        sign: number;
    } {
        const localDate1 = new Date(date1);
        const localDate2 = new Date(date2);
        const diff = localDate1.getTime() - localDate2.getTime();
        // арифметический знак разности, если первая дата, например, меньше (раньше), то знак будет -1
        const sign = Math.sign(diff);
        const millisecondsDiff = Math.abs(diff);
        const secondsDiff = Math.floor(millisecondsDiff / 1000);
        const minutesDiff = Math.floor(secondsDiff / 60);
        const hoursDiff = Math.floor(minutesDiff / 60);
        const daysDiff = Math.floor(hoursDiff / 24);
        const yearsDiff = Math.abs(localDate1.getFullYear() - localDate2.getFullYear());
        const monthsInYear = 12;
        const monthsDiff = Math.abs(localDate1.getMonth() - localDate2.getMonth()) + yearsDiff * monthsInYear;

        return {
            milliseconds: millisecondsDiff,
            seconds: secondsDiff,
            minutes: minutesDiff,
            hours: hoursDiff,
            days: daysDiff,
            months: monthsDiff,
            years: yearsDiff,
            sign,
        };
    }

    // Получить начало дня
    public static getDayStart(date: string | Date): Date {
        const localDate = new Date(date);
        return new Date(localDate.getFullYear(), localDate.getMonth(), localDate.getDate());
    }

    /**
     * Получение количества минут с начала дня переданной даты
     * @param date Дата
     * @returns Количество минут от 0 часов 0 минут переданной даты
     */
    public static getMinutesFromDayStart(date: string | Date): number {
        const localDate = new Date(date);
        return localDate.getHours() * 60 + localDate.getMinutes();
    }

    /**
     * Накладывается ли один временной промежуток на второй
     * @param date Проверяемая дата
     * @param period Период который проверяется на наложение
     * @param minPeriodSize Минимальный размер периода
     * @returns
     */
    public static getIsDateInsidePeriod(date: string | Date, period: {
        start: string | Date;
        end: string | Date;
    }, minPeriodSize?: {
        hours: number
    }): boolean {
        const checkableDate = new Date(date);
        const startDate = new Date(period.start);
        const endDate = new Date(period.end);
        const isInside = (start: Date, end: Date, checkable: Date): boolean => (start <= checkable && checkable < end);

        if (minPeriodSize && DateUtil.getDatesDiff(startDate, endDate).hours < minPeriodSize.hours) {
            const minPeriodEnd = DateUtil.add(startDate, minPeriodSize);
            return isInside(startDate, minPeriodEnd, checkableDate);
        }

        return isInside(startDate, endDate, checkableDate);
    }

    /**
     * Возвращает строку, которая отображет количество прошедшего времени, т.е. 7д 8ч 23мин 43сек
     * @param durationSec - количество секунд
     * @param isFullName - нужно или использовать полные названия ('минута', а не 'мин' и тд)
     */
    // eslint-disable-next-line max-lines-per-function
    public static getDurationTimeString(durationSec: number, isFullName: boolean = false): string {
        durationSec = Math.ceil(durationSec);
        const secondsInMinute = 60;
        const secondsInHour = secondsInMinute * 60;
        const secondsInDay = secondsInHour * 24;

        const days = Math.floor(durationSec / secondsInDay);
        durationSec -= days * secondsInDay;

        const hours = Math.floor(durationSec / secondsInHour);
        durationSec -= hours * secondsInHour;

        const minutes = Math.floor(durationSec / secondsInMinute);
        durationSec -= minutes * 60;

        const tmp = [];
        if (days) {
            if (isFullName) {
                tmp.push(days.cased(
                    localize('день'),
                    localize('дня'),
                    localize('дней'),
                    true,
                ));
            } else {
                tmp.push(days + localize('д'));
            }
        }

        if (days || hours) {
            if (isFullName) {
                tmp.push(hours.cased(
                    localize('час'),
                    localize('часа'),
                    localize('часов'),
                    true,
                ));
            } else {
                tmp.push(hours + localize('ч'));
            }
        }

        if (days || hours || minutes) {
            if (isFullName) {
                tmp.push(minutes.cased(
                    localize('минута'),
                    localize('минуты'),
                    localize('минут'),
                    true,
                ));
            } else {
                tmp.push(minutes + localize('мин'));
            }
        }

        if (isFullName) {
            if (!minutes) {
                tmp.push(durationSec.cased(
                    localize('секунда'),
                    localize('секунды'),
                    localize('секунд'),
                    true,
                ));
            }
        } else {
            if (durationSec > 0) {
                tmp.push(durationSec + localize('сек'));
            }
        }

        return tmp.join(' ');
    }

    /**
     * Возвращает строку, которая отформатирована в HH:MM:SS
     * @param durationSec - количество секунд
     */
    public static getDurationHMS(durationSec: number): string {
        if (durationSec > 3600) {
            // HH:MM:SS
            return new Date(durationSec * 1000).toISOString().substring(11, 19);
        } else {
            // MM:SS
            return new Date(durationSec * 1000).toISOString().substring(14, 19);
        }
    }

    public static getFormatedDateWithTime(date: Date | string): string {
        return `${this.filters.filterDateFull(date)}, ${this.getTime(date)}`;
    }

    // return 3 Ноября, 07:00 — 10 Декабря, 07:00
    public static getFormattedDateRange(startDate: Date | string | undefined, endDate: Date | string | undefined): string {
        if (!startDate && !endDate) {
            return '';
        }

        let formattedDate = '';

        if (startDate) {
            formattedDate = `${this.filters.filterDateFull(startDate)}, ${this.getTime(startDate)}`;
        }

        if (endDate) {
            formattedDate += startDate ? ' — ' : ' ';
            const start = new Date(startDate!);
            const end = new Date(endDate);

            if (start.getDate() === end.getDate() && start.getMonth() === end.getMonth()) {
                formattedDate += this.getTime(endDate);
            } else {
                formattedDate += `${this.filters.filterDateFull(endDate)}, ${this.getTime(endDate)}`;
            }
        }

        return formattedDate;
    }

    // Диапазон дат в формате DD.MM.YYYY – DD.MM.YYYY
    // return 15.06.2021 – 09.08.2021,
    public static getFormattedShortDateRange(startDate: Date | string | null, endDate: Date | string | null): string {
        if (!startDate && !endDate) {
            return '';
        }

        let formattedDate = '';

        if (startDate) {
            formattedDate = `${this.filters.filterDateMonthYear(startDate)}`;
        }

        if (endDate) {
            formattedDate += startDate ? ' — ' : localize('до') + ' ';
            formattedDate += `${this.filters.filterDateMonthYear(endDate)}`;
        }

        return formattedDate;
    }

    public static getFormattedShortWithTimeDate(date: Date | string): string {
        return `${this.filters.filterDateMonthYear(date)}, ${this.getTime(date)}`;
    }

    // Диапазон дат в формате DD.MM.YYYY, TT – DD.MM.YYYY, TT
    // return 15.06.2021, 10:00 – 09.08.2021, 18:00
    public static getFormattedShortWithTimeDateRange(startDate: Date | string | null, endDate: Date | string | null): string {
        if (!startDate && !endDate) {
            return '';
        }

        let formattedDate = '';

        if (startDate) {
            formattedDate = `${this.filters.filterDateMonthYear(startDate)}, ${this.getTime(startDate)}`;
        }

        if (endDate) {
            formattedDate += startDate ? ' — ' : localize('до') + ' ';
            formattedDate += `${this.filters.filterDateMonthYear(endDate)}, ${this.getTime(endDate)}`;
        }

        return formattedDate;
    }

    // Является ли дата сегодняшним днем
    public static isToday(isoString: Date | string): boolean {
        const today = new Date();
        const date = new Date(isoString);
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    }

    // Является ли дата вчера
    public static isYesterday(isoString: Date | string): boolean {
        const today = new Date();
        const yesterday = new Date(today.setDate(today.getDate() - 1));
        const date = new Date(isoString);
        return date.getDate() === yesterday.getDate() &&
            date.getMonth() === yesterday.getMonth() &&
            date.getFullYear() === yesterday.getFullYear();
    }

    // Является ли дата before yesterday
    public static isBeforeYesterday(isoString: Date | string): boolean {
        const today = new Date();
        const beforeYesterday = new Date(today.setDate(today.getDate() - 2));
        const date = new Date(isoString);
        return date.getDate() === beforeYesterday.getDate() &&
            date.getMonth() === beforeYesterday.getMonth() &&
            date.getFullYear() === beforeYesterday.getFullYear();
    }

    // Получаем дату с учетом сохраненного часового пояса пользователя(ODIN-9572)
    public static getDateWithUserOffset(): Date {
        const accountStore = useAccountStore();
        const userTimeZoneOffsetInMinutes: number = accountStore.accountInfo ? accountStore.accountInfo.timeZoneOffsetMinutes : 0;

        // Разница в мс для часового пояса пользователя
        const offsetInMilliseconds = userTimeZoneOffsetInMinutes * 60 * 1000;
        // Разница с UTC на машине пользователя
        const machineTimezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
        const now = new Date().getTime();

        // Чтобы получить правильную дату, нужно добавить оба оффсета
        return new Date(now + offsetInMilliseconds + machineTimezoneOffset);
    }

    // Проверка на сегодня с учетом часового пояса пользователя
    public static isTodayWithUserOffset(isoString: Date | string): boolean {
        const today = DateUtil.getDateWithUserOffset();
        const date = new Date(isoString);
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    }

    // Показывает смещение в секундах с момента начала текущего дня по UTC
    // с учетом передаваемого часового пояса пользователя
    public static getCurrentTimeInSeconds(): number {
        const accountStore = useAccountStore();
        const userTimeZoneOffsetInMinutes: number = accountStore.accountInfo?.timeZoneOffsetMinutes ?? 0;
        const now = new Date();
        const today = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
        const offsetInMilliseconds = userTimeZoneOffsetInMinutes * 60 * 1000;
        let diff = now.getTime() - today;
        diff = diff + offsetInMilliseconds;
        return Math.round(diff / 1000);
    }

    // Когда время приходит в UTC, оно может нам придти из api и из вебсокетов
    // но формат почему-то разный, несмотря на то, что и там и там UTC
    // Из api приходит - '2022-07-29T10:12:22.777502'
    // Из вебсокетов приходит - '2022-07-29T10:12:22.777502Z' - есть буква Z на конце
    // из-за этой 'Z' JS время в UTC приводит к локальному
    // эта функция сделана для того, чтобы вырезать Z из строки времени
    public static trimTimeZone(date: string): string {
        if (date[date.length - 1] === 'Z') {
            return date.substring(0, date.length - 1);
        }

        return date;
    }

    public static getDayWithMonth(date: string, monthFormat: 'numeric' | 'long' | 'short' = 'long'): string {
        return new Date(date).toLocaleDateString(LocalizationService.getISOLanguage(), {
            day: 'numeric',
            month: DateUtil.getFormatParams(monthFormat),
        });
    }

    // Получить название для недели (понедельник, вторник и тд)
    public static getDayName(date: string): string {
        return this.daysOfWeek()[new Date(date).getDay()].day;
    }

    // Преобразует дату в формате 2023-02-12T12:20 в строку 120220231220
    // Нужно для инпута с маской, маска отформатирует строку как дату сама
    public static dateToStringOfNumbers(dateString?: string | null): string {
        if (!dateString) {
            return '';
        }

        const parts = dateString.split('T');
        const date = parts[0].split('-').reverse().join('');
        const dateParts = parts[0].split('-');
        const newDate = new Date(dateString);

        // Проверка на невалидность даты
        // Сравниваем то, что пришло с тем, что в итоге вернул new Date()
        if (newDate.getTime() <= 0 ||
            isNaN(newDate.getTime()) ||
            Number(dateParts[0]) !== newDate.getFullYear() ||
            Number(dateParts[1]) !== (newDate.getMonth() + 1) ||
            Number(dateParts[2]) !== newDate.getDate()) {
            return '';
        }

        return date + parts[1].replace(':', '');
    }

    // Функция возвращает название дня/дату, который был в переданное время dateString
    // то есть, когда это было - Сегодня, Вчера, В пятницу и тд
    public static getSinceDate(dateString: string): string {
        if (DateUtil.isToday(dateString)) {
            return localize('Сегодня');
        } else if (DateUtil.isYesterday(dateString)) {
            return localize('Вчера');
        } else {
            let pastTime = new Date().getTime() - new Date(dateString).getTime();
            pastTime = pastTime / 1000 / 60 / 60 / 24;

            let text;
            let separator;
            const messageDate = new Date(dateString);
            const currentDate = new Date();

            // Если прошла неделя возвращаем число
            if (pastTime >= 7) {
                text = DateUtil.getDayWithMonth(dateString);
                const param = DateUtil.getFormatParams('short');

                // Если вернулся numeric нумерик значит форматируем через точку 12.03.2024
                // а не через пробел 12 марта 2024
                if (param === 'numeric') {
                    separator = '.';
                } else {
                    separator = ' ';
                }
            } else {
                // Иначе возвращаем название дня
                text = DateUtil.getDayName(dateString);
                separator = ' ';
            }

            // Добавляем год к сообщениям из прошлого
            if (messageDate.getFullYear() !== currentDate.getFullYear()) {
                text += separator + messageDate.getFullYear();
            }

            return text;
        }
    }

    /**
     * В Сафари и Firefox есть поддержка языков ближнего зарубежья
     * поэтому всегда оригинальный параметр форматирования
     * для остальных браузеров используем число для отображения месяца и тд
     * @param param
     */
    public static getFormatParams(param: 'numeric' | 'short' | 'long'): 'short' | 'long' | 'numeric' {
        if (this.browserName === 'Safari' || this.browserName === 'Firefox') {
            return param;
        } else {
            if (LocalizationService.getLocaleName() !== LocaleName.RU && LocalizationService.getLocaleName() !== LocaleName.EN) {
                return 'numeric';
            } else {
                return param;
            }
        }
    }

}
