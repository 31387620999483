// Enum, используемый для локализации приложения
export enum LocaleName {
  RU = 'ru',
  EN = 'en',
  UZ = 'uz', // Узбекский
  TG = 'tg', // Таджикский
  KK = 'kk', // Казахский
  MN = 'mn', // Монгольский
  AZ = 'az', // Азербайджанский
  HY = 'hy', // Армянский
  KY = 'ky', // Киргизской
}
