<template>
    <q-scroll-area class="auth-page__scroll-area">
        <q-page class="bg-shade-10 auth-page row justify-center items-center">
            <div v-if="isLoadingData" class="full-width text-center">
                <q-spinner-dots color="primary" size="4em" />
            </div>
            <div v-else class="column q-mb-md" style="flex: 1;">
                <div class="auth-page__top justify-center row items-end">
                    <OdinIcon class="login-odin-icon" aria-hidden="true" />
                    <h1 class="odin-title">Odin</h1>
                </div>
                <q-card v-if="canPassExamInWeb" class="auth-page__form q-pa-md">
                    <div class="text-center q-mb-md text-left">
                        {{ localize('Добро пожаловать на экзамен по русскому языку как иностранному, истории России и основам законодательства РФ.') }}
                        <p>{{ localize('Нажимая кнопку "Продолжить", вы соглашаетесь с условиями проведения экзамена на платформе Odin') }}</p>
                    </div>
                    <div class="row justify-end items-center">
                        <q-btn
                            unelevated
                            color="primary"
                            :label="localize('Продолжить')"
                            @click="login"
                        />
                    </div>
                </q-card>
                <q-card v-else class="auth-page__form q-pa-md">
                    <div class="text-center q-mb-md text-left">
                        {{
                            localize('Прохождение экзамена через веб-версию более недоступно. Экзамен можно провести только через приложение. Скачайте приложение для более быстрой и комфортной работы')
                        }}
                    </div>
                    <div class="row justify-end items-center">
                        <DownloadExamAppButton />
                    </div>
                </q-card>

                <div class="text-center q-mt-lg">
                    <router-link :to="userAgreementRouter" class="auth-page__link q-mb-sm">
                        {{ localize('Пользовательское соглашение') }}
                    </router-link>
                    <br />
                    <router-link :to="confirmRouter" class="auth-page__link">
                        {{ localize('Политика конфиденциальности') }}
                    </router-link>
                </div>
            </div>
            <div class="auth-page__bottom row justify-between full-width">
                <div class="auth-page__support text-center">
                    <div class="auth-page__support_icon">
                        <HelpIcon />
                    </div>
                    <a href="mailto:support@odin.study" target="_blank" class="link">support@odin.study</a>
                </div>
                <LocaleSettings />
            </div>
        </q-page>
    </q-scroll-area>
</template>

<script lang="ts">
    /* eslint comment-density/comment-density: 0 */

    import { computed, defineComponent, onBeforeMount, ref } from 'vue';
    import { localize } from 'src/services/LocalizationService';
    import { RoutePageNameEnum, UserClient } from 'src/api/ApiClient';
    import { Common } from 'src/helpers/Common';
    import { useRoute, useRouter } from 'vue-router';
    import LocaleSettings from 'pages/Login/components/LocaleSettings.vue';
    import DownloadExamAppButton from 'components/DownloadExamAppButton';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';

    export default defineComponent({
        name: 'MigrationExamLogin',

        components: {
            LocaleSettings,
            DownloadExamAppButton
        },

        /* eslint-disable-next-line max-lines-per-function  */
        setup() {
            const $route = useRoute();
            const $router = useRouter();
            const isLoadingData = ref<boolean>(true);
            // Можно ли сдавать экзамен иммиграции в веб-версии
            const canPassExamInWeb = ref<boolean>(false);

            // Ссылка на пользовательское соглашение
            const userAgreementRouter = computed((): string => {
                const path = $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.Agreement),
                    query: { type: 'Agreement' },
                });
                return path.fullPath;
            });

            // Ссылка на политику конфиденциальности
            const confirmRouter = computed((): string => {
                const path = $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.Agreement),
                    query: { type: 'Confidentiality' },
                });
                return path.fullPath;
            });

            function login(): void {
                let route;
                if ($route.path.search('WebExamLogin') !== -1) {
                    route = $router.resolve({
                        path: '/Account/WebExamLoginEnter'
                    });
                } else {
                    route = $router.resolve({
                        name: Common.getRouteName(RoutePageNameEnum.MigrationExamLoginEnter)
                    });
                }
                window.open(route.fullPath, '_blank');
            }

            onBeforeMount(async () => {
                if ($route.path.search('WebExamLogin') !== -1) {
                    canPassExamInWeb.value = true;
                } else {
                    const result = await new UserClient(getApiClientInitialParams()).canPassExamInWeb();
                    canPassExamInWeb.value = result.isSuccess;
                }

                isLoadingData.value = false;
            });

            return {
                isLoadingData,
                userAgreementRouter,
                confirmRouter,
                canPassExamInWeb,
                login,
                localize,
            };
        },
    });

</script>

<style lang="scss">

    .auth-page {
        flex-direction: column;
        flex-wrap: nowrap;

        .q-tabs__content--align-justify {
            .q-tab {
                width: 50%;
            }
        }
    }

    .auth-page__scroll-area {
        height: 100vh;
    }

    @media screen and (max-width: 1000px) {
        .auth-page__scroll-area {
            height: 110vh;
        }
    }

    @media screen and (max-width: 900px) {
        .auth-page__scroll-area {
            height: 120vh;
        }
    }

    @media screen and (max-width: 420px) {
        .auth-page {
            .q-tabs__content--align-justify {
                .q-tab:last-child {
                    width: 53%;
                }
            }
        }
    }
</style>

<style lang="scss" scoped src="./../Login/style.scss"></style>
