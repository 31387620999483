/* eslint-disable */
import adapter from 'webrtc-adapter';
import { Notify } from 'quasar';
import * as Sentry from '@sentry/vue';
import { Janode, VideoroomPlugin } from './janode';
import { ButtonBusEvents, buttonEventBus, janusEventBus } from '../../components/eventBuses';
import { IRemoteFeed } from 'pages/Call/types/interfaces';

export default class JanusWrapper {
    public server: string = '';
    public iceServers: RTCIceServer[] = [];
    public callId: number = 0;
    public userId: number = 0;
    public pin: string = '';
    public mediaDevices: MediaDeviceInfo[] = [];
    // если simulcast: true, то svc должен быть false
    public simulcast: boolean = false;
    // если svc: true, то simulcast должен быть false
    public svc: boolean = true;
    private pubPc: RTCPeerConnection | undefined | null;
    private subPc: RTCPeerConnection | undefined | null;
    private sharingPc: RTCPeerConnection | undefined | null;
    private session: any;
    private janodeConnection: any;
    private videoRoomHandle: any;
    private subscribeHandle: any;
    private sharingScreenHandle: any;
    private userStream: MediaStream | undefined;
    // Для хранения информации о потоках, где ключ - это mid потока, а значение паблишер, у которого этот поток
    private feedStreams = new Map<string, any>();
    private publishers: any[] = [];
    private videoRoomBitrate: number = 512000;
    private sharingBitrate: number = 1024000;
    private isDestroyed: boolean = false;
    private reconnectTryCount: number = 0;
    private browserName: string = '';
    private dataStatItems: any = {};

    public get isVideoInputAvailable(): boolean {
        return this.mediaDevices.some((x) => x.kind === 'videoinput');
    }

    public get isAudioInputAvailable(): boolean {
        return this.mediaDevices.some((x) => x.kind === 'audioinput');
    }

    public get isMutedVideoByDefaultSetting(): boolean {
        return this.isMutedVideoDefaultSetting;
    }

    /* Идентификатор выбранного пользователем устройства для видео ввода, используется для хранения id при пересоздании стрима */
    private selectedVideoInputDeviceId: string | undefined;

    /* Идентификатор выбранного пользователем устройства для аудио ввода, используется для хранения id при пересоздании стрима */
    private selectedAudioInputDeviceId: string | undefined;

    private isMutedVideoDefaultSetting: boolean = true;

    private isMutedAudioDefaultSetting: boolean = true;

    // Идёт ли процесс переподключения
    private isReconnecting: boolean = false;

    /* Идентификатор текущего устройства для видео ввода */
    public get currentVideoInputDeviceId(): string | undefined {
        if (this.selectedVideoInputDeviceId) {
            return this.selectedVideoInputDeviceId;
        } else {
            const videoTracks = this.userStream?.getVideoTracks();
            if (videoTracks && videoTracks.length > 0) {
                return videoTracks[0].getSettings().deviceId;
            } else {
                return undefined;
            }
        }
    }

    /* Идентификатор текущего устройства для аудио ввода */
    public get currentAudioInputDeviceId(): string | undefined {
        if (this.selectedAudioInputDeviceId) {
            return this.selectedAudioInputDeviceId;
        } else {
            const audioTracks = this.userStream?.getAudioTracks();
            if (audioTracks && audioTracks.length > 0) {
                return audioTracks[0].getSettings().deviceId;
            } else {
                return undefined;
            }
        }
    }

    /**
     * Действия после публикации собственного потока
     * @param userRemoteId - это наш собственный feedId
     */
    onPublishOwnFeed = (userRemoteId?: number): void => {
        /* void */
    };

    // Действия с UI, убирающие со страницы элементы, отвечающие
    // за отображение всего, что связано с удаляемым пользователем.
    removeFeed = (remoteFeed: IRemoteFeed): void => {
        /* void */
    };

    // Подключена подписка, но ещё нет потока.
    // В ней можно показать плитку пользователя
    // и выполнить другие действия с UI
    afterAttachRemoteFeed = (remoteFeed: IRemoteFeed): void => {
        /* void */
    };

    // Здесь можно выполнить действия с UI перед подключением потока к элементу video/audio
    // Здесь же можно отключить спиннер, если был запущен.
    // Здесь же необходимо подключить поток к элементу video
    connectRemoteStream = (remoteFeed: IRemoteFeed, kind: string, track?: MediaStreamTrack): void => {
        /* void */
    };

    /**
     * Событие вызывается при переподключении при потере соединения
     * @param isReconnection - true, если происходит переподключение
     */
    changeReconnectingState = (isReconnection: boolean): void => {
        /* void */
    };

    /**
     * Вызывается, когда был создан плагин videoroom
     */
    onInitCall = (): void => {
        /* void */
    };

    /**
     * Вызывается, когда кто-то начали или перестал говорить
     */
    onTalking = (userId: number, isTalking: boolean): void => {
        /* void */
    };

    /**
     * Вызывается, появился поток текущего пользователя
     */
    onLocalStreamReady = (stream: MediaStream): void => {
        /* void */
    };

    constructor() {
        this.browserName = adapter.browserDetails.browser;
    }

    public setSelectedAudioInputDeviceId(deviceId: string): void {
        this.selectedAudioInputDeviceId = deviceId;
    }

    public setSelectedVideoInputDeviceId(deviceId: string): void {
        this.selectedVideoInputDeviceId = deviceId;
    }

    public setMutedAudioDefaultSetting(isMuted: boolean): void {
        this.isMutedAudioDefaultSetting = isMuted;
    }

    public setMutedVideoDefaultSetting(isMuted: boolean): void {
        this.isMutedVideoDefaultSetting = isMuted;
    }

    public async init(): Promise<void> {
        this.isDestroyed = false;
        this.isReconnecting = false;
        this.reconnectTryCount = 0;
        this.dataStatItems = {};
        this.janodeConnection = await Janode.connect({
            address: {
                url: this.server,
            },
            // Количество попыток переподключения 0 тк переподключаться будем сами
            max_retries: 0
        });

        this.janodeConnection.on(Janode.EVENT.CONNECTION_CLOSED, () => {
            console.log('Janode.EVENT.CONNECTION_CLOSED');
        });

        this.janodeConnection.on(Janode.EVENT.CONNECTION_ERROR, (error: any) => {
            console.log('Janode.EVENT.CONNECTION_ERROR', error);
            this.reconnect();
        });

        this.session = await this.janodeConnection.create();

        // Создаем плагин паблишера для текущего пользователя
        this.videoRoomHandle = await this.session.attach(VideoroomPlugin);

        this.videoRoomHandle.on(Janode.EVENT.HANDLE_WEBRTCUP, async (event: any) => {
            console.info('webrtcup HANDLE_WEBRTCUP', event);
        });
        this.videoRoomHandle.on(Janode.EVENT.HANDLE_ICE_FAILED, () => console.info('webrtcup HANDLE_ICE_FAILED'));
        this.videoRoomHandle.on(Janode.EVENT.HANDLE_MEDIA, function (evtdata: any) {
            console.info('media event', evtdata);
        });
        this.videoRoomHandle.on(Janode.EVENT.HANDLE_HANGUP, (event: any) => {
            console.info('hangup event', event);
        });
        this.videoRoomHandle.on(Janode.EVENT.HANDLE_DETACHED, () => {
            console.info('detached event');
        });
        this.videoRoomHandle.on(Janode.EVENT.HANDLE_SLOWLINK, async (evtdata: any) => {
            console.info('videoRoomHandle HANDLE_SLOWLINK', evtdata);

            Sentry.captureEvent({
                message: 'HANDLE_SLOWLINK',
                level: 'info',
                extra: {
                    handle: 'videoRoomHandle',
                    evtdata,
                    stats: await this.getStats('videoRoomHandle')
                }
            });
        });

        this.videoRoomHandle.on(VideoroomPlugin.EVENT.VIDEOROOM_DESTROYED, (evtdata: any) => {
            console.info('destroyed', evtdata);
            this.destroy();
        });

        this.videoRoomHandle.on(VideoroomPlugin.EVENT.VIDEOROOM_PUB_LIST, (evtdata: any) => {
            console.info('feed-list', evtdata);

            if (!this.isDestroyed) {
                // Вызываем pubPeerJoined для паблишера
                // если по каким-то причинам не отработало событие VIDEOROOM_PUB_PEER_JOINED
                evtdata.publishers.forEach((x: any) => {
                    const publisher = this.publishers.find((p) => p.feed === x.feed);

                    if (!publisher) {
                        this.pubPeerJoined(x);
                    }
                });

                this.createSubscribeFeed(evtdata.publishers);
            }
        });

        this.videoRoomHandle.on(VideoroomPlugin.EVENT.VIDEOROOM_ERROR, (evtdata: any) => {
            console.info('VIDEOROOM_ERROR', evtdata);
        });

        this.videoRoomHandle.on(VideoroomPlugin.EVENT.VIDEOROOM_PUB_PEER_JOINED, (evtdata: any) => {
            console.info('feed-joined', evtdata);
            this.pubPeerJoined(evtdata);
        });

        this.videoRoomHandle.on(VideoroomPlugin.EVENT.VIDEOROOM_UNPUBLISHED, (evtdata: any) => {
            console.info('unpublished', evtdata);

            if (this.isDestroyed) {
                return;
            }

            let feed = this.publishers.find((x) => x.display === evtdata.display);

            if (!feed) {
                feed = this.publishers.find((x) => x.feed === evtdata.feed);
            }

            if (feed) {
                this.removeFeed({
                    rfId: feed.feed,
                    rfDisplay: feed.display.replace(/^sharing-/g, ''),
                    isSharing: feed.display.startsWith('sharing-')
                });
            }

            this.unsubscribeFormFeed(evtdata);
        });

        this.videoRoomHandle.on(VideoroomPlugin.EVENT.VIDEOROOM_TALKING, (evtdata: any) => {
            const feed = this.publishers.find((x) => x.feed === evtdata.feed);

            if (feed) {
                this.onTalking(Number(feed.display), evtdata.talking);
            } else {
                this.onTalking(this.videoRoomHandle.feed, evtdata.talking);
            }
        });

        const offer = await this.createOffer();
        const result = await this.videoRoomHandle.joinConfigurePublisher({
            room: this.callId,
            feed: this.videoRoomHandle.feed,
            pin: this.pin,
            display: this.userId.toString(),
            audio: true,
            video: false,
            data: true,
            filename: this.getRecordFileName(false),
            bitrate: this.videoRoomBitrate,
            jsep: offer
        });

        console.log('---> result', result);

        await this.pubPc?.setRemoteDescription(result.jsep);
        this.onInitCall();

        // Если в звонке уже есть паблишерами, то подписываемся на них
        if (result.publishers && result.publishers.length > 0) {
            result.publishers.forEach((p: any) => {
                this.pubPeerJoined(p);
            });

            this.createSubscribeFeed(result.publishers);
        }

        this.onPublishOwnFeed(this.videoRoomHandle.feed);
    }

    /**
     * Сгенерировать событие, которое вернет список доступных устройств ввода/вывода
     */
    public async initMediaDevicesAsync(): Promise<void> {
        const result = await JanusHelper.getMediaDevicesAsync();
        this.mediaDevices = result.devices;
        janusEventBus.emit(JanusEvents.onMediaDevicesChanged, this.mediaDevices);
    }

    public async stopSharing(): Promise<void> {
        await this.sharingScreenHandle?.detach();
        this.closeSharingPc();
        this.sharingScreenHandle = null;
        janusEventBus.emit(JanusEvents.onSharingStopped);
    }

    /**
     * Создать паблишера, который будет передавать поток шаринга
     */
    public async startSharing(): Promise<void> {
        let sharingStream;

        // Сразу пробуем получить поток до создания PeerConnection
        // тк потом браузеры могут заблокировать действия без пользовательского события
        try {
            sharingStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                width: { ideal: 1280 },
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                height: { ideal: 780 },
            });
        } catch (e) {
            janusEventBus.emit(JanusEvents.onSharingError, e);
            return;
        }

        this.sharingScreenHandle = await this.session.attach(VideoroomPlugin);

        const offer = await this.createSharingOffer(sharingStream);
        const result = await this.sharingScreenHandle.joinConfigurePublisher({
            room: this.callId,
            feed: this.sharingScreenHandle.feed,
            pin: this.pin,
            display: 'sharing-' + this.userId.toString(),
            audio: false,
            video: true,
            filename: this.getRecordFileName(true),
            bitrate: this.sharingBitrate,
            jsep: offer
        });

        await this.sharingPc?.setRemoteDescription(result.jsep);
    }

    public muteAudio(): void {
        const track = this.userStream?.getAudioTracks()[0];

        if (track) {
            track.enabled = false;
        }
    }

    public unmuteAudio(): void {
        const track = this.userStream?.getAudioTracks()[0];

        if (track) {
            track.enabled = true;
        }
    }

    /**
     * Удалить видеодорожку и провести повторные переговоры offer/answer
     * @param callback - функция обратного вызова
     */
    public async muteVideo(callback?: () => void): Promise<void> {
        this.userStream?.getVideoTracks().forEach((x: MediaStreamTrack) => {
            x.stop();
            this.userStream?.removeTrack(x);
        });

        const sender = this.pubPc?.getSenders().find((x: RTCRtpSender) => x.track?.kind === 'video');

        if (sender) {
            this.pubPc?.removeTrack(sender);

            const offer = await this.createOffer();
            const result = await this.videoRoomHandle.configure({
                video: false,
                jsep: offer
            });

            await this.pubPc?.setRemoteDescription(result.jsep);
        }

        if (callback) {
            callback();
        }
    }

    /**
     * Добавить/заменить видеодорожку и провести повторные переговоры offer/answer
     * @param stream - поток с видеотреком
     * @param callback - функция обратного вызова
     */
    public async unmuteVideo(stream: MediaStream, callback?: () => void): Promise<void> {
        if (!this.videoRoomHandle || !stream.getVideoTracks().length) {
            return;
        }

        const videoTrack = stream.getVideoTracks()[0];
        const sender = this.pubPc?.getSenders().find((x: RTCRtpSender) => x.track?.kind === 'video');

        if (this.userStream?.getVideoTracks().length) {
            await this.muteVideo();
            setTimeout(() => {
                this.unmuteVideo(stream, callback);
            }, 1000);
            return;

            // if (sender) {
            //     this.userStream?.getVideoTracks().forEach((x: MediaStreamTrack) => {
            //         x.stop();
            //         this.userStream?.removeTrack(x);
            //     });
            //
            //     this.userStream?.addTrack(videoTrack);
            //     await sender.replaceTrack(videoTrack);
            // }
        } else {
            this.userStream?.addTrack(videoTrack);

            if (sender) {
                this.pubPc?.removeTrack(sender);
            }

            if (!this.svc) {
                this.pubPc?.addTrack(videoTrack, this.userStream!);
            }
        }

        if (this.simulcast && !this.svc) {
            this.enableSimulcastForTrack(videoTrack);
        }

        if (this.svc && !this.simulcast) {
            this.enableSVCForTrack(videoTrack);
        }

        const offer = await this.createOffer();
        const result = await this.videoRoomHandle.configure({
            video: true,
            jsep: offer
        });

        await this.pubPc?.setRemoteDescription(result.jsep);

        if (callback) {
            callback();
        }

        this.onLocalStreamReady(this.userStream!);
    }

    public async destroy(): Promise<void> {
        this.isDestroyed = true;
        this.closePubPc();
        this.closeSubPc();
        this.closeSharingPc();
        this.removeLocalTracks();
        // try/catch потому что если сессия уже уничтожилась (например, при потере соединения)
        // то повторный вызов session.destroy вызовет ошибку
        try {
            await this.session.destroy();
            await this.janodeConnection.close();
        } catch (e) {}
        this.session = null;
        this.videoRoomHandle = null;
        this.subscribeHandle = null;
        this.janodeConnection = null;
        this.userStream = undefined;
        this.feedStreams = new Map();
        this.sharingScreenHandle = null;
        this.publishers = [];
        this.dataStatItems = {};
    }

    /**
     * Получить новую аудиодорожку с выбранного устройства и передать её через PeerConnection
     * @param deviceId - выбранный микрофон
     */
    public async changeAudioInputDevice(deviceId: string): Promise<void> {
        this.selectedAudioInputDeviceId = deviceId;

        try {
            const localStream = await navigator.mediaDevices.getUserMedia({
                audio: this.selectedAudioInputDeviceId ? { deviceId: this.selectedAudioInputDeviceId } : true,
                video: false
            });

            const audioTrack = localStream.getAudioTracks()[0];
            const sender = this.pubPc?.getSenders().find((x: RTCRtpSender) => x.track?.kind === 'audio');

            if (sender && audioTrack) {
                audioTrack.enabled = !!this.userStream?.getAudioTracks()[0]?.enabled;

                this.userStream?.getAudioTracks().forEach((x: MediaStreamTrack) => {
                    x.stop();
                    this.userStream?.removeTrack(x);
                });

                this.userStream?.addTrack(audioTrack);
                sender.replaceTrack(audioTrack);
            }
        } catch (e: any) {
            Notify.create({
                type: 'negative',
                message: e.message,
            });
        }
    }

    public getStatsForUser(userId: number, callback: (data: any | null) => void): void {
        if (!this.dataStatItems['video' + userId]) {
            this.dataStatItems['video' + userId] = {
                timer: null,
                bsnow: null,
                bsbefore: null,
                tsnow: null,
                tsbefore: null,
                bitRate: '0 kbits/sec'
            };
        }

        if (!this.dataStatItems['audio' + userId]) {
            this.dataStatItems['audio' + userId] = {};
        }

        if (userId === this.userId) {
            this.dataStatItems['video' + userId].timer = setInterval(async () => {
                const senderVideo = this.pubPc?.getSenders().find((x: RTCRtpSender) => x.track?.kind === 'video');
                const senderAudio = this.pubPc?.getSenders().find((x: RTCRtpSender) => x.track?.kind === 'audio');
                const videoStats = await senderVideo?.getStats();
                const audioStats = await senderAudio?.getStats();

                if (videoStats && videoStats.size > 0) {
                    if (!this.dataStatItems['video' + userId].outbound) {
                        this.dataStatItems['video' + userId].outbound = {};
                    }

                    if (!this.dataStatItems['video' + userId].remoteInbound) {
                        this.dataStatItems['video' + userId].remoteInbound = {};
                    }

                    videoStats.forEach((stat) => {
                        if (stat.mediaType === 'video' && stat.type === 'outbound-rtp') {
                            this.dataStatItems['video' + userId].outbound = { ...stat };
                        }

                        if (stat.mediaType === 'video' && stat.type === 'remote-inbound-rtp') {
                            this.dataStatItems['video' + userId].remoteInbound = { ...stat };
                        }
                    });

                    callback(this.dataStatItems);
                }

                if (audioStats && audioStats.size > 0) {
                    if (!this.dataStatItems['audio' + userId].outbound) {
                        this.dataStatItems['audio' + userId].outbound = {};
                    }

                    if (!this.dataStatItems['audio' + userId].remoteInbound) {
                        this.dataStatItems['audio' + userId].remoteInbound = {};
                    }

                    audioStats.forEach((stat) => {
                        if (stat.mediaType === 'audio' && stat.type === 'outbound-rtp') {
                            this.dataStatItems['audio' + userId].outbound = { ...stat };
                        }

                        if (stat.mediaType === 'audio' && stat.type === 'remote-inbound-rtp') {
                            this.dataStatItems['audio' + userId].remoteInbound = { ...stat };
                        }
                    });

                    callback(this.dataStatItems);
                }
            }, 1000);
        } else {
            this.dataStatItems['video' + userId].timer = setInterval(async () => {
                const publisher = this.publishers.find((x) => x.display === userId.toString());

                if (publisher && this.feedStreams.has(publisher.feed)) {
                    const streams: Map<number, any> = this.feedStreams.get(publisher.feed);
                    const video = Array.from(streams).find((values) => {
                        return values[1].type === 'video';
                    });
                    const audio = Array.from(streams).find((values) => {
                        return values[1].type === 'audio';
                    });

                    if (video) {
                        const transceiver = this.subPc?.getTransceivers().find(t => {
                            return t.mid === video[1].mid && t.receiver.track.kind === 'video';
                        });

                        if (transceiver) {
                            const stats = await transceiver.receiver.getStats();

                            if (stats && stats.size > 0) {
                                if (!this.dataStatItems['video' + userId].inbound) {
                                    this.dataStatItems['video' + userId].inbound = {};
                                }

                                stats.forEach((stat) => {
                                    if (stat.mediaType === 'video' && stat.type === 'inbound-rtp') {
                                        this.dataStatItems['video' + userId].inbound = { ...stat };
                                        const target = this.dataStatItems['video' + userId];
                                        target.bsnow = stat.bytesReceived;
                                        target.tsnow = stat.timestamp;

                                        if (target.bsbefore === null || target.tsbefore === null) {
                                            target.bsbefore = target.bsnow;
                                            target.tsbefore = target.tsnow;
                                        } else {
                                            const timePassed = target.tsnow - target.tsbefore;
                                            const bitRate = Math.round((target.bsnow - target.bsbefore) * 8 / timePassed);
                                            target.bitRate = bitRate + ' kbits/sec';
                                            target.bsbefore = target.bsnow;
                                            target.tsbefore = target.tsnow;
                                        }
                                    }
                                });
                            }

                            callback(this.dataStatItems);
                        }
                    }

                    if (audio) {
                        const transceiver = this.subPc?.getTransceivers().find(t => {
                            return t.mid === audio[1].mid && t.receiver.track.kind === 'audio';
                        });

                        if (transceiver) {
                            const stats = await transceiver.receiver.getStats();

                            if (stats && stats.size > 0) {
                                if (!this.dataStatItems['audio' + userId].inbound) {
                                    this.dataStatItems['audio' + userId].inbound = {};
                                }

                                if (!this.dataStatItems['audio' + userId].remoteOutbound) {
                                    this.dataStatItems['audio' + userId].remoteOutbound = {};
                                }

                                const target = this.dataStatItems['audio' + userId];

                                stats.forEach((stat) => {
                                    if (stat.mediaType === 'audio' && stat.type === 'inbound-rtp') {
                                        target.inbound = { ...stat };
                                    }

                                    if (stat.mediaType === 'audio' && stat.type === 'remote-outbound-rtp') {
                                        target.remoteOutbound = { ...stat };
                                    }
                                });
                            }

                            callback(this.dataStatItems);
                        }
                    }
                }
            }, 1000);
        }
    }

    public stopStatsForUser(userId: number): void {
        clearInterval(this.dataStatItems['video' + userId]?.timer);
        this.dataStatItems['video' + userId] = {};
    }

    /**
     * Создать плагин подписчика и подписать на паблишеров или просто обновить подписки
     * @param publishers - список паблишеров на которых нужно подписаться
     * @private
     */
    private async createSubscribeFeed(publishers: any[]): Promise<void> {
        let offerResult;

        if (!this.subscribeHandle) {
            this.subscribeHandle = await this.session.attach(VideoroomPlugin);

            this.subscribeHandle.on(VideoroomPlugin.EVENT.VIDEOROOM_UPDATED, async (evtdata: any) => {
                console.info('VIDEOROOM_UPDATED', evtdata);

                if (!this.isDestroyed && evtdata.jsep) {
                    const answer = await this.createAnswer(evtdata.jsep);

                    this.subscribeHandle.start({
                        jsep: answer,
                    });
                }
            });

            this.subscribeHandle.on(Janode.EVENT.HANDLE_SLOWLINK, async (evtdata: any) => {
                console.info('subscribeHandle HANDLE_SLOWLINK', evtdata);

                Sentry.captureEvent({
                    message: 'HANDLE_SLOWLINK',
                    level: 'info',
                    extra: {
                        handle: 'subscribeHandle',
                        evtdata,
                        stats: await this.getStats('subscribeHandle')
                    }
                });
            });

            console.log('publishers', publishers);

            offerResult = await this.subscribeHandle.joinSubscriber({
                room: this.callId,
                pin: this.pin,
                streams: publishers.map((x) => {
                    return {
                        feed: x.feed,
                    };
                })
            });
        } else {
            const subscriptions: any[] = [];
            const unsubscriptions: any[] = [];

            publishers.forEach((x) => {
                const alreadyFeed = this.feedStreams.get(x.feed);
                x.streams.forEach((s: any) => {
                    if (s.disabled) {
                        unsubscriptions.push({
                            feed: x.feed,
                            mid: s.mid,
                        });
                        if (alreadyFeed.has(s.mid)) {
                            alreadyFeed.delete(s.mid);
                        }
                    } else {
                        if (!alreadyFeed || !alreadyFeed.has(s.mid)) {
                            subscriptions.push({
                                feed: x.feed,
                                mid: s.mid,
                            });
                        }
                    }
                })
            });

            console.log('+  subscriptions', subscriptions);
            console.log('+  unsubscriptions', unsubscriptions);

            if (subscriptions.length || unsubscriptions.length) {
                offerResult = await this.subscribeHandle.update({
                    subscribe: subscriptions.length ? subscriptions : null,
                    unsubscribe: unsubscriptions.length ? unsubscriptions : null
                });
            }
        }

        publishers.forEach((x: any) => {
            const publisher = this.publishers.find((p) => p.feed === x.feed);

            if (!publisher) {
                this.publishers.push(x);
            }
        });

        console.log('offerResult', offerResult);

        if (offerResult.streams) {
            offerResult.streams.forEach((stream: any) => {
                if (!stream.disabled && stream.feed_id) {
                    if (!this.feedStreams.has(stream.feed_id)){
                        this.feedStreams.set(stream.feed_id, new Map());
                    }
                    this.feedStreams.get(stream.feed_id).set(stream.feed_mid, stream);
                }
            });
        }

        if (offerResult.jsep) {
            const answer = await this.createAnswer(offerResult.jsep);

            this.subscribeHandle.start({
                jsep: answer,
            });
        }
    }

    /**
     * Отписываемся от паблишера, который вышел из звонка
     * @param publisher
     * @private
     */
    private unsubscribeFormFeed(publisher: any): void {
        this.subscribeHandle.update({
            unsubscribe: [{
                feed: publisher.feed
            }]
        });

        if (this.feedStreams.has(publisher.feed)) {
            this.feedStreams.delete(publisher.feed);
        }

        const index = this.publishers.findIndex((p) => p.feed === publisher.feed);

        if (index !== -1) {
            this.publishers.splice(index, 1);
        }

        console.log('--------> this.feedStreams', this.feedStreams);
    }

    /**
     * Создать PeerConnection который будет принимать аудио/видео потоки от всех участников звонка
     * @param offer на который нужно сделать answer
     * @private
     */
    private async createAnswer(offer: any): Promise<any> {
        if (!this.subPc) {
            const pc = new RTCPeerConnection({
                iceServers: this.iceServers
            });

            this.subPc = pc;

            pc.oniceconnectionstatechange = () => {
                if (pc.iceConnectionState === 'failed' || pc.iceConnectionState === 'disconnected') {
                    console.log('!!! subPc.iceConnectionState', pc.iceConnectionState);
                    this.closeSubPc();
                    this.reconnect();
                }
            };
            pc.ontrack = event => {
                console.log('++++++++ pc.ontrack', event);
                this.addTrackEvents(event);
            };
        }

        try {
            await this.subPc.setRemoteDescription(offer);
            console.log('subPc set remote sdp OK');
            const answer = await this.subPc.createAnswer();
            await this.subPc.setLocalDescription(answer);
            console.log('subPc set local sdp OK');
            return answer;
        } catch (e) {
            console.log('error creating subscriber answer', e);
            this.closeSubPc();
            this.reconnect();
            Sentry.captureException(e);
            throw e;
        }
    }

    /**
     * Создать PeerConnection для паблишера (текущего пользователя)
     * который будет передавать аудио/видео дорожки
     * @private
     */
    private async createOffer(): Promise<any> {
        if (!this.pubPc) {
            const pc = new RTCPeerConnection({
                iceServers: this.iceServers
            });

            // pc.onnegotiationneeded = event => console.log('pc.onnegotiationneeded', event);
            // pc.onicecandidate = event => this.trickle({ candidate: event.candidate });
            pc.oniceconnectionstatechange = () => {
                if (pc.iceConnectionState === 'failed' || pc.iceConnectionState === 'disconnected') {
                    console.log('!!! pubPc.iceConnectionState', pc.iceConnectionState);
                    this.closePubPc();
                    this.reconnect();
                }
            };

            this.pubPc = pc;

            try {
                // Аудио получаем всегда, а видеопоток будет передавать из компонета через unmuteVideo
                const localStream = await navigator.mediaDevices.getUserMedia({
                    audio: this.selectedAudioInputDeviceId ? { deviceId: this.selectedAudioInputDeviceId } : true,
                    video: false
                });

                localStream.getAudioTracks().forEach(track => {
                    pc.addTrack(track, localStream);
                });

                this.userStream = localStream;
                this.onLocalStreamReady(this.userStream);

                // Выключаем аудио если мы подключаемся с выключенным микрофоном
                if (this.isMutedAudioDefaultSetting) {
                    this.muteAudio();
                }
            } catch (e) {
                console.log('error while doing offer', e);
                this.closePubPc();
                return;
            }
        } else {
            // console.log('Performing ICE restart');
            // this.pubPc.restartIce();
        }

        try {
            const offer = await this.pubPc.createOffer();
            await this.pubPc.setLocalDescription(offer);
            console.log('pubPc set local sdp OK');
            return offer;
        } catch (e) {
            console.log('error while doing offer', e);
            this.closePubPc();
            this.reconnect();
            Sentry.captureException(e);
            throw e;
        }
    }

    /**
     * Создать PeerConnection, который будет передавать поток шаринга
     */
    private async createSharingOffer(sharingStream: MediaStream): Promise<any> {
        if (!this.sharingPc) {
            const pc = new RTCPeerConnection({
                iceServers: this.iceServers
            });

            pc.oniceconnectionstatechange = () => {
                if (pc.iceConnectionState === 'failed' || pc.iceConnectionState === 'disconnected') {
                    console.log('!!! sharingPc.iceConnectionState', pc.iceConnectionState);
                    this.closeSharingPc();
                }
            };

            this.sharingPc = pc;

            sharingStream.getVideoTracks().forEach(track => {
                pc.addTrack(track, sharingStream);
            });

            janusEventBus.emit(JanusEvents.onSharingStarted, sharingStream);
        }

        try {
            const offer = await this.sharingPc.createOffer();
            await this.sharingPc.setLocalDescription(offer);
            console.log('set sharing local sdp OK');
            return offer;
        } catch (e) {
            console.log('error while doing sharing offer', e);
            this.closeSharingPc();
            return;
        }
    }

    private async trickle(candidate: any): Promise<void> {
        if (this.isDestroyed) {
            return;
        }

        const trickleData: any = candidate ? { candidate } : {};
        trickleData.feed = this.videoRoomHandle;

        try {
            if (candidate) {
                await this.videoRoomHandle.trickle(trickleData);
            } else {
                await this.videoRoomHandle.trickleComplete();
            }
        } catch (e) {
            Janode.Logger.error('error sending trickle', e);
        }
    }

    /**
     * Вешаем на дрожки обработчкики событи чтобы можно было подключать/отключать к HTML элементам
     * @param event - RTCTrackEvent, приходит из события ontrack от PeerConnection
     * @private
     */
    private addTrackEvents(event: RTCTrackEvent): void {
        const submid = event.transceiver?.mid || (event.receiver as any).mid;

        if (!event.track.onunmute) {
            event.track.onunmute = (evt) => {
                const userId = [...this.feedStreams.values()].map(x => [...x.values()]).flat().find(x => x.mid == submid)?.feed_display;
                const isSharing = userId?.startsWith('sharing-');
                console.log('++++++++ track.onunmute', evt, userId, isSharing, event);

                if (userId) {
                    this.connectRemoteStream({
                        rfId: 0,
                        rfDisplay: userId.replace(/^sharing-/g, ''),
                        isSharing: isSharing
                    }, event.track.kind, event.track);
                }

                event.track.onmute = (evt) => {
                    console.log('++++++++ track.onmute', evt, userId, submid, isSharing);

                    if (userId) {
                        // Вызываем для того, чтобы выключить видео
                        this.connectRemoteStream({
                            rfId: 0,
                            rfDisplay: userId.replace(/^sharing-/g, ''),
                            isSharing: isSharing
                        }, event.track.kind, undefined);
                    }
                };
            };
        }
    }

    /**
     * Включаем svc чтобы один видео поток отправлялся в нескольких разрешениях
     * @param videoTrack - MediaStreamTrack
     * @private
     */
    private enableSVCForTrack(videoTrack: MediaStreamTrack): void {
        this.pubPc?.addTransceiver(videoTrack, {
            direction: 'sendonly',
            streams: [this.userStream!],
            sendEncodings: [
                // @ts-ignore
                { scalabilityMode: 'L3T3' },
            ]
        });
    }

    /**
     * Включаем simulcast чтобы один видео поток отправлялся в нескольких разрешениях
     * @param videoTrack - MediaStreamTrack
     * @private
     */
    private enableSimulcastForTrack(videoTrack: MediaStreamTrack): void {
        const maxBitrates = {
            high: 900000,
            medium: 300000,
            low: 100000,
        }

        if (this.browserName === 'firefox') {
            const transceiver = this.pubPc?.addTransceiver(videoTrack);
            let parameters: RTCRtpSendParameters | undefined = transceiver?.sender.getParameters();

            if (!parameters) {
                // @ts-ignore
                parameters = {};
            }

            parameters!.encodings = [
                { rid: 'h', active: true, maxBitrate: maxBitrates.high },
                { rid: 'm', active: true, maxBitrate: maxBitrates.medium, scaleResolutionDownBy: 2 },
                { rid: 'l', active: true, maxBitrate: maxBitrates.low, scaleResolutionDownBy: 4 }
            ];

            transceiver?.sender.setParameters(parameters!);
        } else {
            this.pubPc?.addTransceiver(videoTrack, {
                sendEncodings: [
                    // @ts-ignore
                    { rid: 'h', active: true, scalabilityMode: 'L1T2', maxBitrate: maxBitrates.high },
                    // @ts-ignore
                    { rid: 'm', active: true, scalabilityMode: 'L1T2', maxBitrate: maxBitrates.medium, scaleResolutionDownBy: 2 },
                    // @ts-ignore
                    { rid: 'l', active: true, scalabilityMode: 'L1T2', maxBitrate: maxBitrates.low, scaleResolutionDownBy: 4 }
                ]
            });
        }
    }

    /**
     * Переподключение происходит в трех случаях: отвалился сокет или один из двух PeerConnection
     * @private
     */
    private reconnect(): void {
        if (this.isReconnecting || this.reconnectTryCount >= 5) {
            return;
        }

        this.isReconnecting = true;
        this.changeReconnectingState(true);

        Janode.connect({
            address: {
                url: this.server,
            },
            max_retries: 0
        }).then((connection: any) => {
            connection.close();
            setTimeout(() => {
                buttonEventBus.emit(ButtonBusEvents.Relocate);
            }, 3000);
        }).catch(() => {
            setTimeout(() => {
                this.isReconnecting = false;
                this.reconnectTryCount++;
                this.reconnect();
            }, 5000);
        });
    }

    private closePC(pc: RTCPeerConnection): void {
        if (!pc) return;
        pc.getSenders().forEach(sender => {
            if (sender.track) {
                sender.track.stop();
            }
        });
        pc.getReceivers().forEach(receiver => {
            if (receiver.track) {
                receiver.track.stop();
            }
        });
        pc.onnegotiationneeded = null;
        pc.onicecandidate = null;
        pc.oniceconnectionstatechange = null;
        pc.ontrack = null;
        try {
            pc.close();
        } catch (_e) {
        }
    }

    private closePubPc(): void {
        if (this.pubPc) {
            console.log('closing pc for publisher');
            this.closePC(this.pubPc);
            this.pubPc = null;
        }
    }

    private closeSharingPc(): void {
        if (this.sharingPc) {
            console.log('closing pc for sharing');
            this.closePC(this.sharingPc);
            this.sharingPc = null;
        }
    }

    private closeSubPc(): void {
        if (this.subPc) {
            console.log('closing pc for subscriber');
            this.closePC(this.subPc);
            this.subPc = null;
        }
    }

    private getRecordFileName(isSharing: boolean): string {
        const callId = this.callId;
        const userId = this.userId;
        const clientTime = new Date();
        const year = clientTime.getUTCFullYear();
        let month = clientTime.getUTCMonth();
        const day = clientTime.getUTCDate();
        const hour = clientTime.getUTCHours();
        const minutes = clientTime.getMinutes();
        const seconds = clientTime.getSeconds();
        const milliseconds = clientTime.getMilliseconds();
        const dateString = `${year}-${++month}-${day}__${hour}_${minutes}_${seconds}--${milliseconds}`;
        const fileName = !isSharing ? `userid_${userId}_callid_${callId}_time_${dateString}_time_`
            : `userid_${userId}_callid_${callId}_time_${dateString}_time_sharing`;
        const filesPath = '/tmp/';
        return filesPath + fileName;
    }

    /**
     * Обработать подключенного к звонку пользователя
     * @param feed - feed удаленного пользователя
     * @private
     */
    private pubPeerJoined(feed: { feed: number; display: string }): void {
        const userId = feed.display.replace(/^sharing-/g, '');

        if (feed.display.startsWith('sharing-')) {
            // Игнорируем свой шаринг
            if (userId === this.userId.toString()) {
                return;
            }
        }

        if (!isNaN(Number(userId))) {
            this.afterAttachRemoteFeed({
                rfId: feed.feed,
                rfDisplay: feed.display,
            });
        }
    }

    private removeLocalTracks(): void {
        this.userStream?.getTracks().forEach((x: MediaStreamTrack) => {
            x.stop();
            this.userStream?.removeTrack(x);
        });
    }

    private async getStats(handle: 'videoRoomHandle' | 'subscribeHandle'): Promise<any[] | null> {
        const items: any[] = [];

        if (handle === 'videoRoomHandle') {
            if (!this.pubPc) {
                return null;
            }

            try {
                const stats = await this.pubPc?.getStats();

                stats.forEach((value: any) => {
                    items.push(value);
                });
            } catch (e) {
                return null;
            }
        } else {
            if (!this.subPc) {
                return null;
            }

            try {
                const stats = await this.subPc?.getStats();

                stats.forEach((value: any) => {
                    items.push(value);
                });
            } catch (e) {
                return null;
            }
        }

        return items;
    }
}

export enum JanusEvents {
    onSharingStarted = 'onSharingStarted',
    onSharingStopped = 'onSharingStopped',
    onSharingError = 'onSharingError',
    /** Событие происходит когда изменяется список доступных устройств*/
    onMediaDevicesChanged = 'onMediaDevicesChanged',
    /* Вызывается чтобы выключить видео при переподключении к звонку при потере соединения */
    stopVideoStream = 'stopVideoStream',
    /* Вызывается чтобы включить видео при переподключении к звонку при потере соединения */
    startVideoStream = 'startVideoStream',
}

/** Остановка стрима */
export function stopStream(stream: MediaStream) {
    if (stream) {
        const tracks = stream.getTracks();
        for (const track of tracks) {
            if (track) {
                try {
                    track.stop();
                } catch (e) {}
            }
        }
    }
}

export class JanusHelper {
    /*
      Пытаемся получить информацию об устройствах доступных браузеру ввода/вывода аудио/видео информации.

      Для это этого создаем стрим с помощью navigator.mediaDevices.getUserMedia.
      Подбираем настройку MediaStreamConstraints - это настройки использовать ли камеру, использовать ли аудио.
      Если у пользователя стоят настройки, что сайту запрещено пользоваться аудио или видео,
      стандартная настройка({audio:true, video:true}) выдаст ошибку - для этого и подбор.
      После того, как мы получили стрим, получаем устройства через navigator.mediaDevices.enumerateDevices()
      и останавливаем стрим останавливая каждый трек в нем.

      По сути это копия метода Janus.listDevices, но дополнительно мы отлавливаем ошибки,
      происходящую при попытке получить доступ к заблокированным устройствам, чтобы подобрать значение MediaStreamConstraints.
    */
    static async getMediaDevicesAsync(configForStream?: MediaStreamConstraints): Promise<{ devices: MediaDeviceInfo[] }> {
        return new Promise((resolve) => {
            if (!configForStream) {
                // Начальное предположение. Если оно даст ошибку, значения будут подбираться дальше
                configForStream = { audio: true, video: true };
            }
            // Проверяем, что вообще можем стримить стандартными методами(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices
                    .getUserMedia(configForStream)
                    .then(function(stream) {
                        navigator.mediaDevices.enumerateDevices().then(function(devices) {
                            // Избавляемся от стрима
                            try {
                                const tracks = stream.getTracks();
                                for (const mst of tracks) {
                                    if (mst) {
                                        mst.stop();
                                    }
                                }
                            } catch (e) {}
                            if (!configForStream?.video) {
                                devices = devices.filter((x) => x.kind !== 'videoinput');
                            }

                            resolve({ devices });
                        });
                    })
                    .catch(async () => {
                        if (!configForStream) {
                            configForStream = { audio: false, video: false };
                            resolve({ devices: [] });
                            return;
                        }
                        //Подбираем конфигурацию при ошибке
                        if (configForStream.audio && configForStream.video) {
                            configForStream = { audio: false, video: true };
                        } else if (!configForStream.audio && configForStream.video) {
                            configForStream = { audio: true, video: false };
                        } else if (configForStream.audio && !configForStream.video) {
                            configForStream = { audio: false, video: false };
                            resolve({ devices: [] });
                            return;
                        }

                        const result = await this.getMediaDevicesAsync(configForStream);
                        resolve(result);
                    });
            } else {
                resolve({ devices: [] });
            }
        });
    }
}
