<template>
    <q-scroll-area class="auth-page__scroll-area">
        <q-page class="bg-shade-10 auth-page row justify-center items-center">
            <div v-if="isLoadingData" class="full-width text-center">
                <q-spinner-dots color="primary" size="4em" />
            </div>
            <div v-else class="column q-mb-md" style="flex: 1;">
                <div class="auth-page__top justify-center row items-end">
                    <OdinIcon class="login-odin-icon" aria-hidden="true" />
                    <h1 class="odin-title">Odin</h1>
                </div>
                <q-card v-if="canPassExamInWeb" class="auth-page__form q-pa-md">
                    <q-form @keydown.enter="login">
                        <div class="q-mb-md">
                            {{ localize('Введите код для входа, чтобы пройти экзамен') }}
                        </div>
                        <q-input
                            ref="emailRef"
                            v-model="loginForm.code"
                            type="text"
                            :label="localize('Код для входа')"
                            lazy-rules="ondemand"
                            :rules="[isRequired]"
                            autocomplete="off"
                            mask="################"
                            :error="Boolean(serverErrorsModel.code)"
                            :error-message="serverErrorsModel.code"
                            :no-error-icon="true"
                            @keydown.enter.prevent
                            @update:model-value="onChangeCode"
                        />
                    </q-form>
                    <div class="row justify-end items-center">
                        <q-btn
                            unelevated
                            color="primary"
                            :label="localize('Войти')"
                            @click="login"
                            :disable="isDisabledButton"
                        />
                    </div>
                </q-card>
                <q-card v-else class="auth-page__form q-pa-md">
                    <div class="q-mb-md">
                        {{
                            localize('Прохождение экзамена через веб-версию более недоступно. Экзамен можно провести только через приложение. Скачайте приложение для более быстрой и комфортной работы')
                        }}
                    </div>
                    <div class="row justify-end items-center">
                        <DownloadExamAppButton />
                    </div>
                </q-card>

                <div class="text-center q-mt-lg">
                    <router-link :to="userAgreementRouter" class="auth-page__link q-mb-sm">
                        {{ localize('Пользовательское соглашение') }}
                    </router-link>
                    <br />
                    <router-link :to="confirmRouter" class="auth-page__link">
                        {{ localize('Политика конфиденциальности') }}
                    </router-link>
                </div>
            </div>
            <div class="auth-page__bottom row justify-between full-width">
                <div class="auth-page__support text-center">
                    <div class="auth-page__support_icon">
                        <HelpIcon />
                    </div>
                    <a href="mailto:support@odin.study" target="_blank" class="link">support@odin.study</a>
                </div>
                <LocaleSettings />
            </div>
        </q-page>
    </q-scroll-area>
</template>

<script lang="ts">
    import { computed, defineComponent, getCurrentInstance, onBeforeMount, Ref, ref } from 'vue';
    import { localize } from 'src/services/LocalizationService';
    import { ExamLoginForm } from '../types/LoginForm';
    import { Result, RoutePageNameEnum, UserClient } from 'src/api/ApiClient';
    import { getServerErrorsModel, handleServerErrors, scrollToFirstInvalidField } from 'src/helpers/serverValidation';
    import { Common } from 'src/helpers/Common';
    import useValidationRules from 'src/helpers/custom-hooks/useValidationRules';
    import useValidateAllFields from 'src/helpers/custom-hooks/useValidateAllFields';
    import { Notify, QInput } from 'quasar';
    import { useRoute, useRouter } from 'vue-router';
    import { NotifyUtil } from 'src/shims-vue';
    import { useAccountStore } from 'src/store/module-account';
    import { useAuthorizationStore } from 'src/store/module-authorization';
    import { useChatStore } from 'src/store/module-chat';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import LocaleSettings from 'pages/Login/components/LocaleSettings.vue';
    import DownloadExamAppButton from 'components/DownloadExamAppButton';

    export default defineComponent({
        name: 'MigrationExamLogin',

        components: {
            DownloadExamAppButton,
            LocaleSettings
        },

        /* eslint-disable-next-line max-lines-per-function  */
        setup() {
            const app = getCurrentInstance();
            const chatStore = useChatStore();
            const accountStore = useAccountStore();
            const authorizationStore = useAuthorizationStore();
            const $route = useRoute();
            const $router = useRouter();

            const emailRef = ref<QInput>();
            const isLoadingData = ref<boolean>(true);

            // Можно ли сдавать экзамен иммиграции в веб-версии
            const canPassExamInWeb = ref<boolean>(false);

            // Правила для валидации
            const { isRequired } = useValidationRules();

            // Модель для формы логина
            const loginForm: Ref<ExamLoginForm> = ref({
                code: null,
            });

            // Заблокировать кнопку пока вылолнятеся запрос авторизации
            const isDisabledButton: Ref<boolean> = ref(false);

            const serverErrorsModel: Ref<Record<string, string>> = ref(getServerErrorsModel(loginForm.value));

            // Ссылка на пользовательское соглашение
            const userAgreementRouter = computed((): string => {
                const path = $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.Agreement),
                    query: { type: 'Agreement' },
                });
                return path.fullPath;
            });

            // Ссылка на политику конфиденциальности
            const confirmRouter = computed((): string => {
                const path = $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.Agreement),
                    query: { type: 'Confidentiality' },
                });
                return path.fullPath;
            });

            async function login(): Promise<void> {
                const { isInvalid } = useValidateAllFields(app?.refs);

                if (isInvalid) {
                    // Прокрутить страницу до первого не валидного элемента
                    scrollToFirstInvalidField();
                    return;
                }

                isDisabledButton.value = true;

                // Залогиниться в системе и получить токен
                const result: Result = await authorizationStore.examLogin({
                    code: loginForm.value.code
                });

                if (result.isSuccess) {
                    // Получаем информацию о пользователе
                    const accountInfo = await accountStore.loadAccountInfo();

                    if (!accountInfo) {
                        return;
                    }

                    chatStore.loadChatsUnreadedCount();
                    app?.appContext.config.globalProperties.$fcmService?.sync();

                    const redirectDataResult = await new UserClient(getApiClientInitialParams()).getExamRedirectData();

                    if (redirectDataResult.isSuccess) {
                        // Если есть информация о комнате, то сразу начинаем звонок
                        // Иначе открываем страницу теста
                        if (!!redirectDataResult.entity.chatRoomId) {
                            await $router.push({
                                name: Common.getRouteName(RoutePageNameEnum.CallEnter),
                                params: {
                                    id: redirectDataResult.entity.chatRoomId.toString(),
                                },
                                query: {
                                    activityId: redirectDataResult.entity.activityId,
                                    start: 'true',
                                    isMigration: 'true',
                                }
                            });

                            (Notify as NotifyUtil).$closeNotify?.forEach((el: any) => {
                                el(void 0);
                            });
                        } else {
                            await $router.push({
                                name: Common.getRouteName(RoutePageNameEnum.ExamineeVariantPass),
                                params: { id: redirectDataResult.entity.activityId }
                            });
                            return;
                        }
                    } else {
                        NotifyErrors(redirectDataResult);
                    }
                } else {
                    handleServerErrors(result, serverErrorsModel.value);
                }

                isDisabledButton.value = false;
            }

            function onChangeCode(event: string | number | null): void {
                if (!event) {
                    emailRef.value?.resetValidation();
                }
            }

            onBeforeMount(async () => {
                if ($route.path.search('WebExamLoginEnter') !== -1) {
                    canPassExamInWeb.value = true;
                } else {
                    const result = await new UserClient(getApiClientInitialParams()).canPassExamInWeb();
                    canPassExamInWeb.value = result.isSuccess;
                }

                isLoadingData.value = false;
            });

            return {
                emailRef,
                isRequired,
                loginForm,
                isDisabledButton,
                serverErrorsModel,
                userAgreementRouter,
                confirmRouter,
                isLoadingData,
                canPassExamInWeb,
                getServerErrorsModel,
                handleServerErrors,
                login,
                localize,
                onChangeCode,
            };
        },
    });

</script>

<style lang="scss">

    .auth-page {
        flex-direction: column;
        flex-wrap: nowrap;

        .q-tabs__content--align-justify {
            .q-tab {
                width: 50%;
            }
        }
    }

    .auth-page__scroll-area {
        height: 100vh;
    }


    @media screen and (max-width: 1000px) {
        .auth-page__scroll-area {
            height: 110vh;
        }
    }

    @media screen and (max-width: 900px) {
        .auth-page__scroll-area {
            height: 120vh;
        }
    }

    @media screen and (max-width: 420px) {
        .auth-page {
            .q-tabs__content--align-justify {
                .q-tab:last-child {
                    width: 53%;
                }
            }
        }
    }
</style>

<style lang="scss" scoped src="./../Login/style.scss"></style>
