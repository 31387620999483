// Фильтрация полей
// При необходимости, список можно расширить
import LocalizationService from 'src/services/LocalizationService';
import { UserBaseInfoDto } from 'src/api/ApiClient';
import { LocaleName } from 'src/types/LocaleName';
import DateUtil from 'src/helpers/DateUtil';

export type Filters = {
    substringName: (e: string) => string;
    filterDate: (e: Date | string) => string;
    filterDateFull: (e: Date | string) => string;
    filterDateYear: (e: string) => string;
    filterFullDateYear: (e: string) => string;
    filterFullDateYearAndTime: (e: string) => string;
    filterFullDateShortMonthYearAndTime: (e: string) => string;
    filterDateMonthShortAndTime: (e: string) => string;
    filterDateMonthLongAndTime: (e: string) => string;
    filterDateMonthYear: (e: Date | string) => string;
    getDateAndTime: (e: string) => string;
    formatTime: (e: Date | string) => string;
    filterMonthNameByNumber: (n: number, capitalize?: boolean) => string;
    formatName: (e: UserBaseInfoDto) => string;
    formatNameFull: (e: UserBaseInfoDto) => string;
    filterFullDateShortYearAndTime: (e: string) => string;
    floatFloor: (e: number | null | undefined) => number | null | undefined;
    formatCurrencyRub: (n: number, fractionDigits: number) => string;
};

// eslint-disable-next-line max-lines-per-function
export default function useFilters(): Filters {
    // Обрезать слово до 35 символов и вставить точку
    const substringName = (e: string): string => {
        return e.length > 35 ? e.substr(0, 35) + '...' : e;
    };

    // Получить строку отформатированную в денежном формате с символом рубля в конце
    const formatCurrencyRub = (n: number, fractionDigits: number): string => {
        return n.toLocaleString(LocalizationService.getISOLanguage(), {
            style: 'currency',
            currency: 'RUB',
            maximumFractionDigits : fractionDigits
        });
    };

    // Форматирует дату в 28 янв
    const filterDate = (e: Date | string, month: 'short' | 'long' = 'short'): string => {
        const rawDate = (typeof e === 'string') ?  new Date(e) : e;
        const monthParam = DateUtil.getFormatParams(month);
        const date = rawDate.toLocaleDateString(LocalizationService.getISOLanguage(), {
            day: 'numeric',
            month: monthParam,
        });

        if (monthParam === 'numeric') {
            return date;
        } else {
            return date.replace(/[.]/g, '');
        }
    };

    // Форматирует дату в 28 января
    const filterDateFull = (e: Date | string): string => {
        return filterDate(e, 'long');
    };

    // Форматирует дату в 28 янв 2022
    const filterDateYear = (e: string): string => {
        const monthParam = DateUtil.getFormatParams('short');
        const locale = LocalizationService.getISOLanguage();
        const date = new Date(e).toLocaleDateString(locale, {
            day: 'numeric',
            month: monthParam,
            year: 'numeric',
        });

        if (monthParam === 'numeric') {
            return date;
        } else {
            if (locale === LocaleName.RU) {
                return date.replace(/\sг\.$/, ''); // Удаление " г." после года в ru локале
            } else {
                return date;
            }
        }
    };

    // Форматирует дату в 28 января 2022 г
    const filterFullDateYear = (e: string): string => {
        const monthParam = DateUtil.getFormatParams('long');
        const date = new Date(e).toLocaleDateString(LocalizationService.getISOLanguage(), {
            day: 'numeric',
            month: monthParam,
            year: 'numeric',
        });

        if (monthParam === 'numeric') {
            return date;
        } else {
            return date.replace(/[.]/g, '');
        }
    };

    // Форматирует дату в 28 января 2022 г, 13:57
    const filterFullDateYearAndTime = (e: string): string => {
        return filterFullDateYear(e) + '., ' + new Date(e).toLocaleTimeString().slice(0, -3);
    };

    // Форматирует дату в 28 января, 2022, 13:57
    const filterFullDateShortYearAndTime = (e: string): string => {
        const locale = LocalizationService.getISOLanguage();
        if (locale === LocaleName.RU) {
            return filterFullDateYearAndTime(e).replace(/\sг\.$/, ''); // Удаление " г." после года в ru локале;
        } else {
            return filterFullDateYearAndTime(e);
        }
    };

    // Форматирует дату в 28 янв., 2022, 13:57
    const filterFullDateShortMonthYearAndTime = (e: string): string => {
        const monthParam = DateUtil.getFormatParams('short');
        let date = new Date(e).toLocaleDateString(LocalizationService.getISOLanguage(), {
            day: 'numeric',
            month: monthParam,
        });

        if (monthParam !== 'numeric') {
            date = date.replace(/[.]/g, '');
            date += ' ';
        } else {
            date += '.';
        }

        date += new Date(e).toLocaleTimeString();
        return date.substring(0, date.length - 3);
    };

    // Форматирует дату в 28 янв., 13:58
    const filterDateMonthShortAndTime = (e: string, month: 'short' | 'long' = 'short'): string => {
        const monthParam = DateUtil.getFormatParams(month);
        return new Date(e).toLocaleDateString(LocalizationService.getISOLanguage(), {
            day: 'numeric',
            month: monthParam,
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    // Форматирует дату в 28 января, 13:59
    const filterDateMonthLongAndTime = (e: string): string => {
        return filterDateMonthShortAndTime(e, 'long');
    };

    // Форматирует дату в 28.01.2022
    const filterDateMonthYear = (e: Date | string): string => {
        const date = (typeof e === 'string') ?  new Date(e) : e;
        return date.toLocaleDateString(LocalizationService.getISOLanguage(), {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        });
    };

    // Форматирует дату в 13:58
    const formatTime = (e: Date | string): string => {
        const date = (typeof e === 'string') ?  new Date(e) : e;
        return date.toLocaleTimeString(LocalizationService.getISOLanguage(), {
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    // Форматирует дату в 04.11.2024 08:23:34
    function getDateAndTime(date: string): string {
        const newDate: Date = new Date(date);
        return newDate.toLocaleDateString(LocalizationService.getISOLanguage()) + ' ' + newDate.toLocaleTimeString(LocalizationService.getISOLanguage());
    }

    // Формат "Фамилия И. О." / "Фамилия И."
    const formatName = (e: UserBaseInfoDto): string => {
        return `${e.lastName} ${e.firstName[0] + '.'} ${e.middleName ? e.middleName[0] + '.' : ''}`;
    };

    // Полное Ф.И.О.
    const formatNameFull = (e: UserBaseInfoDto): string => {
        return `${e.lastName} ${e.firstName} ${e.middleName ? e.middleName : ''}`;
    };

    // Округляет переданное дробное число
    function floatFloor(num: number | null | undefined): number | null | undefined {
        if (!num && num !== 0) {
            return null;
        }

        return Math.round(num * 100) / 100;
    }

    // Получить название месяца по его номеру.
    // capitalize - указывает что нужно вернуть название с заглавной буквы
    // так как в разных локалях месяц может писаться с заглавной буквы, а может с прописной
    // n-1 потому что monthIndex - Integer value representing the month, beginning with 0 for January to 11 for December.
    const filterMonthNameByNumber = (n: number, capitalize: boolean = false): string => {
        const str = DateUtil.monthNames[n-1];
        return capitalize
            ? str.charAt(0).toUpperCase() + str.slice(1)
            : str.charAt(0).toLowerCase() + str.slice(1);
    };

    return {
        substringName,
        filterDate,
        filterDateFull,
        filterDateYear,
        filterFullDateYear,
        filterFullDateYearAndTime,
        filterDateMonthShortAndTime,
        filterDateMonthLongAndTime,
        filterDateMonthYear,
        formatTime,
        getDateAndTime,
        filterMonthNameByNumber,
        formatName,
        formatNameFull,
        filterFullDateShortYearAndTime,
        filterFullDateShortMonthYearAndTime,
        floatFloor,
        formatCurrencyRub,
    };
};
