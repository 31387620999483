<template>
    <q-list class="left-menu-university-components">
        <router-link
            v-for="item in universities"
            :key="item.id"
            class="left-menu-university-components__item row items-center link"
            :to="getUniversityPageUrl(item.id)"
        >
            <div class="left-menu-university-components__item_text">{{ item.shortName }}</div>
        </router-link>
    </q-list>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { RoutePageNameEnum, UniversitySelectModel } from 'src/api/ApiClient';
    import { Common } from 'src/helpers/Common';
    import { useRouter } from 'vue-router';


    // Компонент для отображения
    // университетов в левом меню сайта
    export default defineComponent({
        name: 'LeftMenuUniversity',
        props: {
            // Список университотов в меню
            // Обьязательное поле(список университетов)
            universities: {
                type: Array as PropType<UniversitySelectModel[]>,
                required: true,
            }
        },
        /* eslint-disable-next-line max-lines-per-function  */
        setup() {
            const $router = useRouter();

            // Формирование ссылок на детальную университета
            function getUniversityPageUrl(id: number): string {
                const path = $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.UniversityInfo),
                    params: { id: id.toString() }
                });
                return path.fullPath;
            }

            return  {
                getUniversityPageUrl,
            };
        }
    });
</script>

<style lang="scss" scoped>
    .left-menu-university-components {
        position: relative;

        &:before {
            content: '';
            width: calc(100% - 12px);
            height: 1px;
            background-color: #EEF1F3;
            right: 0;
            bottom: 0;
            position: absolute;
        }

        &__item {
            padding: 12px;
            color: $link;
        }

        @media (max-width: 960px) {
            padding-left: 45px;
            padding-bottom: 10px;
            margin-bottom: 16px;
            width: 100%;

            &__item {
                margin: 15px 0;
                padding: 5px;
                min-height: auto;
                font-size: 14px;
            }
        }
    }
</style>

<style lang="scss">
    .left-menu-university-components__item {
        .q-focus-helper {
            background: transparent !important;
        }
    }
</style>
