import {
    SetupContext,
    onUnmounted,
    onMounted,
    ref, Ref,
} from 'vue';

export type MobileResize = {
    isMobileView: Ref<boolean>;
};

// Хук используется для определения мобильного размера экрана
export default function useMobileResize(context: SetupContext, size: number = 700): MobileResize {
    const isMobileView = ref<boolean>(false);

    // Установить мобильное отображение
    function setMobileView(): void {
        isMobileView.value = window.innerWidth <= size;
    }

    onMounted(() => {
        setMobileView();
        window.addEventListener('resize', setMobileView);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', setMobileView);
    });

    return {
        isMobileView,
    };
}
