import { LocaleName } from 'src/types/LocaleName';
import { ILocalizationStore } from './interfaces';
import localizableStrings from 'src/services/LocalizationService/localizableStrings';
import {
    getApiClientInitialParams,
    HttpStatusCode,
    LanguageEnum,
    LocalizationClient,
    LocalizationItemDto,
    ResultOf,
} from 'src/api/ApiClient';
import { Md5 } from 'ts-md5';
import * as Sentry from '@sentry/vue';
import { useMainLayoutStore } from 'src/store/module-main-layout';
import Bowser from 'bowser';

const LOCALIZATION_KEY = 'localization-resources';
// сколько часов мы храним данные в LS
const COUNT_HOURS_STORE = 24;

class Localization {
    private requestPromise: Promise<LocalizationItemDto[]> | null = null;

    private localizationItems: Record<string, LocalizationItemDto> = {};

    // Хэши строк для которых уже был сделан запрос к api на перевод
    private requestedTexts: Set<string> = new Set<string>();

    private localeName: LocaleName = LocaleName.RU;

    private readyCallbacks: Array<() => void> = [];

    private localizationStore!: IDBDatabase;

    constructor() {
        this.localeName = this.getLocaleName();
    }

    public async init(): Promise<void> {
        return new Promise((resolve: () => void, reject: () => void) => {
            const localizationStoreRequest = indexedDB.open('OdinLocalizationNew', 1);

            localizationStoreRequest.onsuccess = async () => {
                this.localizationStore = localizationStoreRequest.result;
                await this.getData(reject);
                this.updateAllLocalesInBackground();
                resolve();
            };

            localizationStoreRequest.onupgradeneeded = () => {
                this.localizationStore = localizationStoreRequest.result;

                for (const localeNameKey in LocaleName) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const storeKey = LOCALIZATION_KEY + '_' + LocaleName[localeNameKey].toString();

                    if (!this.localizationStore.objectStoreNames.contains(storeKey)) {
                        this.createObjectStore(storeKey);
                    }
                }
            };

            localizationStoreRequest.onerror = (event: any) => {
                console.error('IndexedDB onerror', event);
                Sentry.captureException('IndexedDB onerror', {
                    extra: {
                        message: event.target.error.toString()
                    }
                });
            };
        });
    }

    public get isReady(): boolean {
        return Object.keys(this.localizationItems).length > 0;
    }

    public getLocaleName(): LocaleName {
        const storedData = localStorage.getItem(LOCALIZATION_KEY);

        if (storedData) {
            const locData: ILocalizationStore = JSON.parse(storedData);
            return locData.currentLocale || LocaleName.RU;
        }

        return this.localeName;
    }

    /**
     * Функция используется для получения локали при форматировании дат
     * В Сафари и Firefox есть поддержка языков ближнего зарубежья
     * поэтому всегда возвращаем текущую локаль
     * для остальных браузеров используем русский язык при форматировании дат
     */
    public getISOLanguage(): string {
        const localeName = this.getLocaleName();
        const browserName = Bowser.getParser(window.navigator.userAgent).getBrowserName();

        if (browserName === 'Safari' || browserName === 'Firefox') {
            return localeName;
        } else {
            if (localeName === LocaleName.RU || localeName === LocaleName.EN) {
                return localeName;
            } else {
                return LocaleName.RU;
            }
        }
    }

    public setLocaleName(locale: LocaleName): void {
        this.localeName = locale;
        const storedData = localStorage.getItem(LOCALIZATION_KEY);

        if (storedData) {
            const locData: ILocalizationStore = JSON.parse(storedData);
            const newLocData: ILocalizationStore = {
                ...locData,
                currentLocale: this.localeName,
            };

            newLocData['createTime_' + this.localeName] = locData['createTime_' + this.localeName];
            localStorage.setItem(LOCALIZATION_KEY, JSON.stringify(newLocData));
        }
    }

    // Добавляем обработчики события, которые вызовутся
    // когда данные локализации будут инициализированы
    public onReady(callback: () => void): void {
        this.readyCallbacks.push(callback);
    }

    private createObjectStore(storeName: string): void {
        this.localizationStore.createObjectStore(storeName, {
            keyPath: 'hash'
        });
    }

    private async getData(reject: () => void): Promise<Record<string, LocalizationItemDto>> {
        let objectStore = await this.getObjectStore();
        const localizationMetaString = localStorage.getItem(LOCALIZATION_KEY);
        const dbResult = await this.dbRequest(objectStore.count.bind(objectStore));
        const resourcesIsEmpty: boolean = !dbResult || dbResult.result === 0;

        if (localizationMetaString && !resourcesIsEmpty) {
            const localizationMeta: ILocalizationStore = JSON.parse(localizationMetaString);
            this.localeName = localizationMeta.currentLocale || LocaleName.RU;
            const creationTime = Number(localizationMeta['createTime_' + this.localeName]);

            const clearDataForStore = async (): Promise<void> => {
                delete localizationMeta['createTime_' + this.localeName];
                localStorage.setItem(LOCALIZATION_KEY, JSON.stringify(localizationMeta));
                objectStore = await this.getObjectStore();
                await this.dbRequest(objectStore.clear.bind(objectStore));
            };

            // если данные устарели, то грузим заново
            if (isNaN(creationTime) || COUNT_HOURS_STORE * 60 * 60 * 1000 < Date.now() - creationTime) {
                await clearDataForStore();
                return this.getData(reject);
            }

            const lastModifyDate = await this.getLastModifyDate(reject);

            // lastModifyDate - в секундах
            if (isNaN(creationTime) || creationTime < lastModifyDate * 1000) {
                await clearDataForStore();
                return this.getData(reject);
            }

            objectStore = await this.getObjectStore();
            const data = await this.dbRequest(objectStore.getAll.bind(objectStore));

            data?.result.forEach((item: LocalizationItemDto) => {
                this.localizationItems[item.hash] = item;
            });

            this.callReadyCallbacks();
            return new Promise((resolve: (value: Record<string, LocalizationItemDto>) => void) => resolve(this.localizationItems));
        } else {
            const loadedData = await this.loadData(reject);

            for (const item of loadedData) {
                this.localizationItems[item.hash] = item;
            }

            await this.writeDataToIndexedDB(loadedData);

            let localizationStored: ILocalizationStore | null = null;
            let localizationMeta: ILocalizationStore = {} as ILocalizationStore;

            if (localizationMetaString) {
                localizationStored = JSON.parse(localizationMetaString) as ILocalizationStore;
                localizationMeta = { ...localizationStored };
            }

            localizationMeta.currentLocale = this.localeName;
            localizationMeta['createTime_' + this.localeName] = Date.now();
            localStorage.setItem(LOCALIZATION_KEY, JSON.stringify(localizationMeta));
            this.callReadyCallbacks();
            return new Promise((resolve: (value: Record<string, LocalizationItemDto>) => void) => resolve(this.localizationItems));
        }
    }

    private async loadData(reject: () => void): Promise<LocalizationItemDto[]> {
        // kind of pattern Singleton
        // вызовов может быть несколько одновременно
        // поэтому сохраняем промис и всем его возвращаем
        // что бы был только один запрос к серверу
        // и все компоненты дожидались его
        if (!this.requestPromise) {
            this.requestPromise = new Promise(async (resolve: (value: LocalizationItemDto[]) => void) => {
                try {
                    const response = await new LocalizationClient(getApiClientInitialParams()).getData(this.convertLocaleEnum());

                    if (response.isSuccess) {
                        resolve(response.entity.localizationData);
                    } else {
                        // Показываем окно "Что-то пошло не так" ддя всех 500-тых ошибок
                        if (response.httpStatusCode !== HttpStatusCode.OK) {
                            useMainLayoutStore().isVisibleSmthWentWrongBlock = true;
                        }
                    }
                } catch (error) {
                    this.requestPromise = null;
                    reject();
                }
            });
        }

        return this.requestPromise;
    }

    /**
     * Обновляем все языки, которые обновлялись больше чем сутки назад
     * @private
     */
    private async updateAllLocalesInBackground(): Promise<void> {
        const localizationMeta = localStorage.getItem(LOCALIZATION_KEY);

        if (!localizationMeta) {
            return;
        }

        const storedData = JSON.parse(localizationMeta);
        let index = 0;

        for (const localeNameKey in LocaleName) {
            index++;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const localeKey = LocaleName[localeNameKey];

            if (localeKey !== this.localeName) {
                const creationTime = Number(storedData['createTime_' + localeKey]);

                if (isNaN(creationTime) || COUNT_HOURS_STORE * 60 * 60 * 1000 < Date.now() - creationTime) {
                    const storeKey = LOCALIZATION_KEY + '_' + localeKey.toString();
                    storedData['createTime_' + localeKey] = Date.now();
                    localStorage.setItem(LOCALIZATION_KEY, JSON.stringify(storedData));
                    const objectStore = await this.getObjectStore();
                    await this.dbRequest(objectStore.clear.bind(objectStore));

                    // Каждый следующий запрос вызывается через три секунды
                    setTimeout((data) => {
                        this.loadLocaleData(data);
                    }, index * 3000, {
                        storeKey,
                        localeKey
                    });
                }
            }
        }
    }

    private async loadLocaleData(params: { storeKey: string, localeKey: LocaleName }): Promise<void> {
        const result = await new LocalizationClient(getApiClientInitialParams()).getData(this.convertLocaleEnum(params.localeKey));

        if (result.isSuccess) {
            this.writeDataToIndexedDB(result.entity.localizationData, params.storeKey);
        }
    }

    private convertLocaleEnum(locale?: LocaleName): LanguageEnum | null {
        switch (locale || this.localeName) {
            case LocaleName.RU:
                return LanguageEnum.Origin;
            case LocaleName.EN:
                return LanguageEnum.En;
            case LocaleName.UZ:
                return LanguageEnum.Uz;
            case LocaleName.TG:
                return LanguageEnum.Tg;
            case LocaleName.KK:
                return LanguageEnum.Kk;
            case LocaleName.MN:
                return LanguageEnum.Mn;
            case LocaleName.AZ:
                return LanguageEnum.Az;
            case LocaleName.HY:
                return LanguageEnum.Hy;
            case LocaleName.KY:
                return LanguageEnum.Ky;
            default:
                return null;
        }
    }

    // Создать новую транзацицю тк предыдущая может быть закрыта из-за асинхронных операций
    private async getObjectStore(): Promise<IDBObjectStore> {
        if (!this.localizationStore) {
            await this.init();
        }

        const transaction = this.localizationStore.transaction(LOCALIZATION_KEY + '_' + this.localeName, 'readwrite');
        return transaction.objectStore(LOCALIZATION_KEY + '_' + this.localeName);
    }

    // Записать сразу все данные в indexedDB
    private writeDataToIndexedDB(data: LocalizationItemDto[], storeKey?: string): Promise<void> {
        return new Promise(async (resolve: () => void) => {
            const key = storeKey || LOCALIZATION_KEY + '_' + this.localeName;
            const transaction = this.localizationStore.transaction(key, 'readwrite');
            const objectStore = transaction.objectStore(key);
            await this.dbRequest(objectStore.clear.bind(objectStore));

            data.forEach((item: LocalizationItemDto) => {
                transaction.objectStore(key).add(item);
            });

            transaction.oncomplete = resolve;
            transaction.onerror = (error: Event) => {
                console.error('Ошибка при добавлении данных локализации в indexedDB', error);
            };
        });
    }

    // Вспомогательная функция для запросов к indexedDB
    // Дело в том, что функции получения данных синхронные, но результат они не возвращают
    // результат нужно обрабатывать в колбэке onsuccess
    // И эта функция для удобства оборачивает запрос в Promise
    private async dbRequest(requestFunc: () => IDBRequest): Promise<IDBRequest | null> {
        return new Promise((resolve: (data: IDBRequest | null) => void) => {
            const request = requestFunc();
            request.onsuccess = () => {
                resolve(request);
            };
            request.onerror = (event: any) => {
                resolve(null);
                console.error('Ошибка выполнения запроса к indexedDB', event);
                Sentry.captureException('Ошибка выполнения запроса к indexedDB', {
                    extra: {
                        message: event.target.error.toString()
                    }
                });
            };
        });
    }

    private callReadyCallbacks(): void {
        this.readyCallbacks.forEach((callback: () => void, index: number) => {
            callback();
            this.readyCallbacks.splice(index, 1);
        });
    }

    private async getLastModifyDate(reject: () => void): Promise<number> {
        return new Promise(async (resolve: (value: number) => void) => {
            try {
                const response: ResultOf<number> = await new LocalizationClient(getApiClientInitialParams()).getLastModified();
                if (response.httpStatusCode === 200) {
                    resolve(response.entity);
                } else {
                    // Показываем окно "Что-то пошло не так" ддя всех 500-тых ошибок
                    if (String(response.httpStatusCode)[0] === '5') {
                        useMainLayoutStore().isVisibleSmthWentWrongBlock = true;
                    } else {
                        // В случае ошибки проставляем текущее время и грузим переводы ODIN-8824
                        resolve(Math.round(Date.now() / 1000));
                    }
                }
            } catch (e) {
                reject();
            }
        });
    }

    private async addNewText(text: string, hash: string): Promise<void> {
        // чтобы не было много запросов для одной фразы, проверяем
        // был ли для неё запрос или нет
        if (this.requestedTexts.has(hash)) {
            return;
        }

        this.requestedTexts.add(hash);

        const response = await new LocalizationClient(getApiClientInitialParams()).addNewText({
            text,
            language: this.convertLocaleEnum()
        });

        if (response.isSuccess) {
            this.localizationItems[response.entity.hash] = response.entity;
            const objectStore = await this.getObjectStore();
            objectStore.add(response.entity);
        }
    }

    private formatWith(text: string, formatterArguments: Record<string, string | number> | null = null): string {
        if (typeof formatterArguments !== 'object' || formatterArguments == null) {
            return text;
        }
        for (const key in formatterArguments) {
            if (formatterArguments.hasOwnProperty(key)) {
                const value = formatterArguments[key]?.toString();
                if (value) {
                    text = text.replace(new RegExp(`{${key}}`, 'gi'), value);
                }
            }
        }

        return text;
    }

    localize(text: string, formatterArguments: Record<string, string | number> | null = null): string {
        if (!this.isReady) {
            // сохраняем данные, чтобы каждый раз не лазить в localStorage
            this.getObjectStore()
                .then((objectStore: IDBObjectStore) => {
                    this.dbRequest(objectStore.getAll.bind(objectStore)).then((data: IDBRequest | null) => {
                        data?.result.forEach((item: LocalizationItemDto) => {
                            this.localizationItems[item.hash] = item;
                        });
                    });
                });

            // Из-за того, что операция считывания из хранилища является асинхронной
            // приходится делать костыль на случай, если оно ещё пустое, т.к. если сейчас сделать метод ассинхронным,
            // то придется менять абсолютно во всем проекте вызов метода translate
            return text;
        }

        const hash = Md5.hashStr(text);
        const resourceItem = this.localizationItems[hash];

        if (!resourceItem) {
            this.addNewText(text, hash);
        }

        const result = resourceItem == null || this.localeName === LocaleName.RU ? text : resourceItem.en;
        return this.formatWith(result, formatterArguments);
    }
}

const LocalizationService = new Localization();

export default LocalizationService;

// Пока не удаляем параметр scope, т.к. получится слишком много изменений. Лучше выпилить его отдельным PR
export function localize(text: string, formatterArguments: Record<string, string | number> | null = null): string {
    return LocalizationService.localize(text, formatterArguments);
}

// Перевести строку, но данные брать не из стора, а из массива localizableStrings
export function translateFromLocalizableStrings(text: string): string {
    const translateItem = localizableStrings.find((x: LocalizationItemDto) => x.origin === text);

    if (translateItem) {
        if (LocalizationService.getLocaleName() === LocaleName.RU) {
            return translateItem.origin;
        } else {
            return translateItem.en;
        }
    } else {
        return text;
    }
}
