<template>
    <div :id="containerId" class="smart-captcha-component" />
</template>

<script lang="ts">
    import { defineComponent, onBeforeUnmount, onMounted } from 'vue';
    import LocalizationService from 'src/services/LocalizationService';
    import { LocaleName } from 'src/types/LocaleName';

    type WidgetId = number;

    type WindowWithCaptcha = Window & {
        smartCaptcha?: SmartCaptcha | null;
        onSmartCaptchaLoaded?: () => void;
    }

    interface SmartCaptcha {
        render: (
            container: HTMLElement | string,
            params: {
                sitekey: string;
                callback?: (token: string) => void;
                hl?: LocaleName;
                invisible?: boolean;
            }
        ) => WidgetId;
        subscribe: (widgetId: WidgetId, event: string, handler: (...args: any) => any) => void;
        relad: (widgetId: WidgetId) => void;
        destroy: (widgetId?: WidgetId) => void;
    }

    export default defineComponent({
        name: 'VueSmartcaptcha',

        props: {
            // Ключ капчи
            siteKey: {
                type: String,
                required: true,
            },

            // ИД скрипта
            scriptId: {
                type: String,
                default: '_SMART_CAPTCHA_'
            },

            // ИД контейнера, где должна быть капча
            containerId: {
                type: String,
                default: 'smart-captcha-container'
            }
        },

        emits: ['success', 'network-error', 'challenge-hidden', 'challenge-visible'],

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            let widgetId: WidgetId; // ИД виджета капчи

            // Чтобы все работало, нам нужно два дополнительных поля: smartCaptcha и onSmartCaptchaLoaded
            // smartCaptcha нам заполнит Яндекс объектом, через который мы будем взаимодействовать с АПИ
            // onSmartCaptchaLoaded мы заполним сами, его вызовет Яндекс, когда нужно будет инициализировать
            // АПИ параметрами
            const windowWithCaptcha: WindowWithCaptcha = window;

            // Обработчик события загрузки скриптов капчи
            function onSmartCaptchaLoaded(): void {
                if (!windowWithCaptcha.smartCaptcha) {
                    return;
                }

                renderCaptcha();
                subscribeHandlers();
            }

            // Прикрепить <script> с капчей
            function addSmartCaptchaScript(): void {
                const script = document.createElement('script');
                script.id = props.scriptId;
                script.src = 'https://captcha-api.yandex.ru/captcha.js?render=onload&onload=onSmartCaptchaLoaded';
                script.async = true;
                script.defer = true;

                document.head.appendChild(script);
            }

            // Подписать обработчики для передачи событий капчи через события vue
            function subscribeHandlers(): void {
                windowWithCaptcha.smartCaptcha?.subscribe(
                    widgetId,
                    'success',
                    (token: string) => context.emit('success', token)
                );
                windowWithCaptcha.smartCaptcha?.subscribe(
                    widgetId,
                    'network-error',
                    () => context.emit('network-error')
                );
                windowWithCaptcha.smartCaptcha?.subscribe(
                    widgetId,
                    'challenge-visible',
                    () => context.emit('challenge-visible')
                );
            }

            // Вызвать отрисовку капчи
            function renderCaptcha(): void {
                const lang = LocalizationService.getLocaleName();
                if (windowWithCaptcha.smartCaptcha) {
                    widgetId = windowWithCaptcha.smartCaptcha.render(props.containerId, {
                        sitekey: props.siteKey,
                        hl: lang
                    });
                }
            }

            // Сбросить капчу
            function reset(): void {
                windowWithCaptcha.smartCaptcha?.relad(widgetId);
            }

            onMounted(() => {
                if (!document.getElementById(props.scriptId)) {
                    addSmartCaptchaScript();
                }

                if (windowWithCaptcha.smartCaptcha) {
                    renderCaptcha();
                    subscribeHandlers();
                    return;
                }

                windowWithCaptcha.onSmartCaptchaLoaded = onSmartCaptchaLoaded;
            });

            onBeforeUnmount(() => {
                windowWithCaptcha.smartCaptcha?.destroy(widgetId);
            });

            return { reset };
        }
    });
</script>

<style lang="scss">
    .smart-captcha-component {
        iframe {
            padding-right: 1px;
        }
    }
</style>
