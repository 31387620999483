<template>
    <div>
        <div
            :style="{ right: isVisibleCallsBlock ? width + 16 + 'px' : '16px' }"
            class="chat-buttons"
        >
            <div
                v-if="hasCalls && !isVisibleCallsBlock"
                @click="showCurrentCalls"
                role="button"
                tabindex="0"
                :aria-label="localize('Открыть блок с текущими звонками')"
                class="chat-button cursor-pointer flex justify-evenly items-center"
            >
                <Icon name="AcceptCallIcon" class="shake-animate" size="26" aria-hidden="true" />
                <div class="q-ml-sm">{{ localize('Текущие звонки') }}</div>
                <div class="notify"></div>
            </div>
            <div
                v-if="!isVisibleChatBlock || isVisibleCallsBlock"
                @click="showChats"
                role="button"
                tabindex="0"
                :aria-label="localize('Открыть блок с чатами')"
                class="chat-button cursor-pointer flex justify-evenly items-center q-ml-sm"
            >
                <ChatIcon aria-hidden="true" />
                <div class="q-ml-sm">{{ localize('Чат') }}</div>
                <div class="notify" v-if="countUnreadedMessages"></div>
            </div>
        </div>

        <q-drawer
            v-model="isVisibleChatBlock"
            :width="width"
            :breakpoint="mobileBreakpoint"
            side="right"
            @hide="onHide"
            @show="onShow"
            class="chat-block-component"
            no-swipe-close
            no-swipe-backdrop
            :aria-label="localize('Блок с чатами')"
        >
            <div
                v-if="!isMobileView"
                @click="onHide"
                class="change-mode-button flex justify-center items-center"
                role="button"
                :aria-label="localize('Скрыть блок с чатами')"
            >
                <ArrowRightIcon aria-hidden="true" />
            </div>

            <Header ref="chatHeaderRef" @on-select-chat="onSelectChat" />
            <Chat
                ref="chatRef"
                mode-chat="inline"
                :is-mobile-view="isMobileView"
                @on-load="onLoadChat"
            />
        </q-drawer>

        <q-resize-observer @resize="setSize" :debounce="500" />
    </div>
</template>

<script lang="ts">
    import { localize } from 'src/services/LocalizationService';
    import Chat from 'components/Chat/Chat.vue';
    import {
        ChatBaseInfoResponseModel,
        ChatClient,
        ChatType,
        ListChatDto,
        UserBaseInfoDto,
    } from 'src/api/ApiClient';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import Header from './components/Header.vue';
    import { chatBus } from 'components/EventBuses';
    import { convertChatBaseInfoToInfoListItem, convertListChatDtoToInfoListItem } from 'pages/Main/Chat/helpers';
    import {
        computed,
        defineComponent,
        nextTick,
        onBeforeMount,
        onBeforeUnmount,
        onMounted,
        ref,
        Ref,
        watch,
    } from 'vue';
    import { useAccountStore } from 'src/store/module-account';
    import { useNotificationsStore } from 'src/store/module-notifications';
    import { useMainLayoutStore } from 'src/store/module-main-layout';
    import { useChatStore } from 'src/store/module-chat';
    import { ChatBusEvents } from 'components/EventBuses/emuns';

    /**
     * Обёртка чата с дополнительной информацией и контролами
     */
    export default defineComponent({
        name: 'ChatBlock',
        components: {
            Header,
            Chat,
        },
        emits: [
            'change-chat-block',
        ],
        // eslint-disable-next-line max-lines-per-function
        setup(_, context) {
            const chatStore = useChatStore();
            const accountStore = useAccountStore();
            const notificationsStore = useNotificationsStore();
            const mainLayoutStore = useMainLayoutStore();

            const chatRef = ref<InstanceType<typeof Chat> | null>(null);
            const chatHeaderRef = ref<InstanceType<typeof Header> | null>(null);

            const mobileBreakpoint = 960;
            const isVisibleOnDesktop: Ref<boolean> = ref(false);

            /**
             * Занимаемая компонентом ширина экрана
             */
            const width: Ref<number> = ref(0);
            /**
             * Нужно ли отображать в режиме для мобильных устройств
             */
            const isMobileView: Ref<boolean> = ref(false);

            // Отображается ли блок чата
            const isVisibleChatBlock = computed({
                get(): boolean {
                    return mainLayoutStore.isVisibleChatBlock;
                },
                set(value: boolean) {
                    mainLayoutStore.isVisibleChatBlock = value;
                },
            });

            // Нужно ли показывать кнопку для раскрытия текущих звонко
            const isVisibleCallsBlock = computed({
                get(): boolean {
                    return mainLayoutStore.isVisibleCallsBlock;
                },
                set(value: boolean) {
                    mainLayoutStore.isVisibleCallsBlock = value;
                }
            });

            // Непрочитанные сообщения по всем чатам
            const countUnreadedMessages = computed((): number => {
                return chatStore.getAllUreadedMessagesCount;
            });

            // Есть ли текущие звонки
            const hasCalls = computed((): boolean => {
                return notificationsStore.getCallNotifications.length > 0;
            });

            watch(isVisibleChatBlock, () => {
                changeChatVisibilityHandler();
                if (window.innerWidth > mobileBreakpoint) {
                    isVisibleOnDesktop.value = isVisibleChatBlock.value;
                }
            });

            function onSelectChat(chat: ListChatDto): void {
                if (chat.type === ChatType.Discipline && chat.isDisabled) {
                    if (!chatRef.value) {
                        return;
                    }

                    chatRef.value.showDisabledChat(chat.chatId!);
                } else {
                    // Если мы выбрали чат, то chatId у него точно есть
                    setChatId(chat.chatId!);
                }
            }

            function setChatId(chatId: number): void {
                chatRef.value?.setChatId(chatId);
                chatHeaderRef.value?.setChat(chatId);
            }

            // Передаём данные в chatHeader что бы отобразить чат среди всех чатов
            // если его нет в списке загруженных, при этом мы исключаем
            // чаты решений и чаты не дефолтных комнат, тк они не должны быть в шапке
            async function onLoadChat(chatInfo: ChatBaseInfoResponseModel): Promise<void> {
                const isDisciplineChat = chatInfo.type === ChatType.Discipline;

                // Если это чат дисциплины, то выходим, если это не дефолтная комната чата
                if (isDisciplineChat && !chatInfo.isDefaultRoom) {
                    const result = await new ChatClient(getApiClientInitialParams()).getDefaultInfoChatByChatId(chatInfo.id);

                    if (result.isSuccess) {
                        const chatListItem = convertListChatDtoToInfoListItem(result.entity.defaultChatInfo);
                        chatHeaderRef.value?.setChat(result.entity.defaultChatInfo.chatId!, chatListItem);
                    }
                    return;
                }

                // В остальных случаях проверяем только на то, чтобы чат не был чатом решения
                if (chatInfo.type === ChatType.Solution) {
                    const result = await new ChatClient(getApiClientInitialParams()).getDefaultInfoChatByActivityId(chatInfo.activityId!);
                    if (result.isSuccess) {
                        const chatListItem = convertListChatDtoToInfoListItem(result.entity.defaultChatInfo);
                        chatHeaderRef.value?.setChat(result.entity.defaultChatInfo.chatId!, chatListItem);
                    }
                } else {
                    chatHeaderRef.value?.setChat(chatInfo.id, convertChatBaseInfoToInfoListItem(chatInfo));
                }
            }

            function changeChatVisibilityHandler(): void {
                context.emit('change-chat-block', isVisibleChatBlock.value);
                if (!isVisibleChatBlock.value) {
                    isVisibleCallsBlock.value = false;
                }
            }

            function showChats(): void {
                isVisibleChatBlock.value = true;
                isVisibleCallsBlock.value = false;
            }

            function showCurrentCalls(): void {
                isVisibleChatBlock.value = true;
                isVisibleCallsBlock.value = true;
            }

            function setSize(): void {
                isMobileView.value = window.innerWidth <= mobileBreakpoint;
                if (isMobileView.value) {
                    width.value = window.innerWidth;
                    onHide();
                } else {
                    // Восстанавливаем видимость чата с десктопной
                    isVisibleChatBlock.value = isVisibleOnDesktop.value;

                    // ширина чата 23% (или 28) от ширины экрана
                    const percentOfPage = window.innerWidth <= 1024 ? 28 : 23;
                    width.value = (window.innerWidth * percentOfPage) / 100;
                    changeChatVisibilityHandler();
                }
            }

            function onHide(): void {
                isVisibleChatBlock.value = false;
            }

            function onShow(): void {
                isVisibleChatBlock.value = true;
            }

            function showSelfChat(): void {
                const myselfChatId = accountStore.getAccountInfo?.myselfChatId;

                // И если есть чат с собой открываем его
                // Иначе будет показана заглушка "Выберите чат"
                if (myselfChatId) {
                    nextTick(function () {
                        if (chatRef.value) {
                            chatRef.value.setChatId(myselfChatId);
                        }
                    });
                } else {
                    console.info('chatSelfId is undefined');
                }
            }

            // Инициализация компонента
            onBeforeMount(() => {
                // Чат могут открыть из любого места
                // вешаем обработчик события event bus
                chatBus.on(ChatBusEvents.SetChatId, (chatId: number) => {
                    setChatId(chatId);

                    if (!isVisibleChatBlock.value) {
                        isVisibleChatBlock.value = true;
                    }
                });

                chatBus.on(ChatBusEvents.SetPrivateChatUserData, (userData: UserBaseInfoDto) => {
                    chatRef.value?.setPrivateChatUserData(userData);

                    if (!isVisibleChatBlock.value) {
                        isVisibleChatBlock.value = true;
                    }
                });

                showSelfChat();
                isVisibleOnDesktop.value = isVisibleChatBlock.value;
                setSize();

                if (isMobileView.value) {
                    isVisibleChatBlock.value = false;
                }
            });

            onMounted(() => {
                nextTick(changeChatVisibilityHandler);
            });

            onBeforeUnmount(() => {
                chatBus.off(ChatBusEvents.SetChatId);
            });

            return {
                isVisibleChatBlock,
                isVisibleCallsBlock,
                chatHeaderRef,
                chatRef,
                showChats,
                width,
                countUnreadedMessages,
                hasCalls,
                showCurrentCalls,
                onHide,
                onShow,
                isMobileView,
                onSelectChat,
                localize,
                onLoadChat,
                setSize,
                setChatId,
                mobileBreakpoint,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .change-mode-button {
        position: absolute;
        top: 180px;
        left: -18px;
        width: 40px;
        height: 40px;
        z-index: 99999;
        border-radius: 20px;
        background-color: #fff;
        box-shadow: 0px 1px 0px rgb(0 0 0 / 16%);
        cursor: pointer;

        &:hover {
            background-color: $shade-5;
        }
    }

    .chat-buttons {
        display: flex;
        position: absolute;
        right: 16px;
        bottom: var(--bottomActionPanelHeight);
        z-index: 10;

        .chat-button {
            padding: 0 8px;
            height: 32px;
            background-color: $accent;
            border-radius: 8px 8px 0 0;
            color: #fff;

            ::v-deep(svg path) {
                stroke: #fff;
            }

            .notify {
                position: absolute;
                width: 10px;
                height: 10px;
                right: -4px;
                top: -4px;
                background-color: $negative;
                border-radius: 50%;
                border: 2px solid #fff;
            }
        }
    }

    @media (max-width: 960px) {
        .chat-button {
            display: none;
        }
    }
</style>

<style lang="scss">
    .q-drawer--right,
    .q-drawer__backdrop {
        top: 51px;
    }
</style>
