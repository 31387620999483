﻿<template>
    <div
        v-if="selectedLanguage"
        @mouseenter="showMenu"
        class="row cursor-pointer items-center"
    >
        <component :is="selectedLanguage.iconName" class="flag-icon" aria-hidden="true"></component>
        <div class="language-name">{{ showShortTitle ? selectedLanguage.shortTitle : selectedLanguage.title }}</div>
        <Icon
            name="ForwardIcon"
            v-if="showArrowIcon"
            :size="arrowIconSize"
            use-raw-color
            class="q-ml-sm"
        />

        <q-menu v-model="isVisible" @mouseleave="isVisible = false">
            <q-list style="width: 160px;">
                <template v-for="(item, index) in languages" :key="index">
                    <q-item
                        v-if="item.value !== selectedLanguage.value"
                        @click="setLocale(item.value)"
                        clickable
                        v-close-popup
                    >
                        <q-item-section>
                            <div class="row items-center">
                                <component :is="item.iconName" class="flag-icon" aria-hidden="true"></component>
                                {{ item.title }}
                            </div>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
        </q-menu>
    </div>
</template>

<script setup lang="ts">
    /* eslint comment-density/comment-density: 0 */

    import { LocaleName } from 'src/types/LocaleName';
    import LocalizationService from 'src/services/LocalizationService';
    import { LanguagesEnum, UserClient } from 'src/api/ApiClient';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import { onBeforeMount, ref } from 'vue';
    import { useAuthorizationStore } from 'src/store/module-authorization';

    interface ILanguageItem {
        value: LocaleName;
        title: string;
        shortTitle: string;
        iconName: string;
    }

    defineProps({
        // Отображать сокращенное название выбранного языка
        showShortTitle: {
            type: Boolean,
            default: false
        },
        // Показывать иконку-стрелку
        showArrowIcon: {
            type: Boolean,
            default: false
        },
        // Размер иконку-стрелку
        arrowIconSize: {
            type: String,
            default: '24'
        }
    });

    const authorizationStore = useAuthorizationStore();
    const selectedLanguage = ref<ILanguageItem | null>(null);
    const isVisible = ref<boolean>(false);

    const languages: ILanguageItem[] = [{
        value: LocaleName.RU,
        title: 'Русский',
        shortTitle: 'РУС',
        iconName: 'RussiaIcon'
    }, {
        value: LocaleName.AZ,
        title: 'Azərbaycan',
        shortTitle: 'AZ',
        iconName: 'AzerbaijanIcon'
    }, {
        value: LocaleName.EN,
        title: 'English',
        shortTitle: 'EN',
        iconName: 'UnitedKingdomIcon'
    }, {
        value: LocaleName.HY,
        title: 'հայերէն',
        shortTitle: 'Հայ',
        iconName: 'ArmeniaIcon'
    }, {
        value: LocaleName.KK,
        title: 'Қазақ',
        shortTitle: 'ҚАЗ',
        iconName: 'KazakhstanIcon'
    }, {
        value: LocaleName.KY,
        title: 'Кыргыз',
        shortTitle: 'КЫР',
        iconName: 'KyrgyzstanIcon'
    }, {
        value: LocaleName.MN,
        title: 'Монгол',
        shortTitle: 'МОН',
        iconName: 'MongoliaIcon'
    }, {
        value: LocaleName.TG,
        title: 'Тоҷикӣ',
        shortTitle: 'ТOҶ',
        iconName: 'TajikistanIcon'
    }, {
        value: LocaleName.UZ,
        title: 'O\'zbek',
        shortTitle: 'O\'Z',
        iconName: 'UzbekistnIcon'
    }];

    // Изменить язык интерфейса, если не совпадает с текущим языком интерфейса
    async function setUserLanguage(locale: LocaleName): Promise<void> {
        const language = convertLocaleEnum(locale);
        const result = await new UserClient(getApiClientInitialParams()).setLanguage({ language });

        if (!result.isSuccess) {
            NotifyErrors(result);
        }
    }

    // Изменить язык интерфейса, если не совпадает с текущим языком интерфейса
    async function setLocale(locale: LocaleName): Promise<void> {
        if (authorizationStore.getIsLogged) {
            await setUserLanguage(locale);
        }

        const currentLocale = LocalizationService.getLocaleName().toLocaleLowerCase();
        LocalizationService.setLocaleName(locale);

        if (currentLocale !== locale) {
            location.href = location.href.replace(`/${currentLocale}/`, `/${locale}/`);
        }
    }

    function convertLocaleEnum(locale: LocaleName): LanguagesEnum {
        switch (locale) {
            case LocaleName.RU:
                return LanguagesEnum.Russian;
            case LocaleName.EN:
                return LanguagesEnum.English;
            case LocaleName.UZ:
                return LanguagesEnum.Uzbek;
            case LocaleName.TG:
                return LanguagesEnum.Tajik;
            case LocaleName.KK:
                return LanguagesEnum.Kazakh;
            case LocaleName.MN:
                return LanguagesEnum.Mongolian;
            case LocaleName.AZ:
                return LanguagesEnum.Azerbaijani;
            case LocaleName.HY:
                return LanguagesEnum.Armenian;
            case LocaleName.KY:
                return LanguagesEnum.Kyrgyz;
            default:
                return LanguagesEnum.Russian;
        }
    }

    // На мобилке показываем меню по клику, а не по ховеру
    function showMenu(): void {
        // Мобильное отображение начинается с 960px
        const mobileWidthMax: number = 960;

        if (window.innerWidth > mobileWidthMax) {
            isVisible.value = true;
        }
    }

    onBeforeMount(() => {
        const currentLocale = LocalizationService.getLocaleName();

        for (let i = 0; i < languages.length; i++) {
            if (languages[i].value === currentLocale) {
                selectedLanguage.value = languages[i];
                break;
            }
        }
    });
</script>

<style scoped lang="scss">
    .flag-icon {
        margin-right: 12px;
    }
</style>

<style lang="scss">
    @media (max-width: 960px) {
        .auth-page {
            .language-name {
                display: none;
            }
        }
    }
</style>
