<template>
    <q-scroll-area class="auth-page__scroll-area">
        <q-page class="bg-shade-10 auth-page row justify-center items-center">
            <div class="column q-mb-md" style="flex: 1;">
                <div class="auth-page__top justify-center row items-end">
                    <OdinIcon class="login-odin-icon" aria-hidden="true" />
                    <h1 class="odin-title">Odin</h1>
                </div>
                <q-card class="auth-page__form">
                    <q-tabs
                        v-model="currentTab"
                        active-color="primary"
                        indicator-color="primary"
                    >
                        <q-tab
                            name="login"
                            :label="localize('Войти')"
                        />
                        <q-tab
                            name="register"
                            :label="localize('Зарегистрироваться')"
                            @click="register"
                        />
                    </q-tabs>
                    <q-tab-panels v-model="currentTab">
                        <q-tab-panel name="login" class="q-pb-none">
                            <q-form @keydown.enter="login">
                                <q-input
                                    ref="emailRef"
                                    v-model="loginForm.email"
                                    type="email"
                                    label="Email"
                                    :rules="[isRequiredEmail]"
                                    lazy-rules="ondemand"
                                    :error="Boolean(serverErrorsModel.email)"
                                    :error-message="serverErrorsModel.email"
                                    :no-error-icon="true"
                                    @update:model-value="onChangeEmail"
                                />
                                <input-password
                                    ref="passwordRef"
                                    v-model="loginForm.password"
                                    :label="localize('Пароль')"
                                    :rules="[isRequired]"
                                    :error="Boolean(serverErrorsModel.password)"
                                    :error-message="serverErrorsModel.password"
                                />
                            </q-form>
                            <div class="row justify-between items-center">
                                <router-link
                                    :to="{name: forgotPasswordPageName}"
                                    class="auth-page__link"
                                >
                                    {{ localize('Забыли пароль?') }}
                                </router-link>
                                <q-btn
                                    unelevated
                                    color="primary"
                                    :label="localize('Войти')"
                                    @click="login"
                                    :disable="isDisabledButton"
                                />
                            </div>
                            <div class="another-auth-block">
                                <div class="another-auth-block__title">{{ localize('Или войдите с помощью') }}</div>

                                <div class="row justify-start items-center">
                                    <a
                                        :href="tsuAuthUrl"
                                        class="auth-page__form_social_item"
                                    >
                                        <Icon
                                            name="TsuIcon"
                                            size="24px"
                                            :use-raw-color="true"
                                        />
                                    </a>
                                    <a
                                        :href="yandexAuthUrl"
                                        class="auth-page__form_social_item q-mx-md"
                                    >
                                        <Icon
                                            name="YandexIcon"
                                            size="24px"
                                            :use-raw-color="true"
                                        />
                                    </a>
                                    <a
                                        :href="googleAuthUrl"
                                        class="auth-page__form_social_item"
                                    >
                                        <GoogleIcon />
                                    </a>
                                    <a
                                        :href="vkAuthUrl"
                                        class="auth-page__form_social_item q-mx-md"
                                    >
                                        <VkIcon />
                                    </a>
                                    <a
                                        :href="university2035AuthUrl"
                                        class="auth-page__form_social_item"
                                    >
                                        <University2035Icon />
                                    </a>
                                </div>
                            </div>
                        </q-tab-panel>
                    </q-tab-panels>
                </q-card>

                <div class="text-center q-mt-lg">
                    <router-link :to="userAgreementRouter" class="auth-page__link q-mb-sm">
                        {{ localize('Пользовательское соглашение') }}
                    </router-link>
                    <br />
                    <router-link :to="confirmRouter" class="auth-page__link">
                        {{ localize('Политика конфиденциальности') }}
                    </router-link>
                </div>
            </div>
            <div class="auth-page__bottom row justify-between full-width">
                <div class="auth-page__support text-center">
                    <div class="auth-page__support_icon">
                        <HelpIcon />
                    </div>
                    <a href="mailto:support@odin.study" target="_blank" class="link">support@odin.study</a>
                </div>
                <LocaleSettings />
            </div>

            <q-dialog v-model="employmentModal">
                <q-card class="confirm-modal__content">
                    <q-card-section class="confirmModal__border-none">
                        <div class="confirm-modal__title">{{ localize('Регистрация в Odin') }}</div>
                        <div class="confirm-modal__text">{{ localize(`Вы являетесь участником проекта "Содействие
                        занятости"?`) }}
                        </div>
                    </q-card-section>

                    <q-card-actions align="right">
                        <q-btn flat :label="localize('Нет')" color="red" :to="{name: registerPageName}" />
                        <q-btn flat :label="localize('Да')" color="primary" @click="employmentClose" />
                    </q-card-actions>
                </q-card>
            </q-dialog>

            <q-dialog v-model="modalInfo">
                <q-card class="confirm-modal__content">
                    <q-card-section class="confirmModal__border-none">
                        <div class="confirm-modal__title">{{ localize('Немного терпения') }}</div>
                        <div class="confirm-modal__text">{{ localize(`Вам необходимо дождаться письма на электронную почту, в
                        котором будет логин и ссылка на установку пароля для входа Odin. Пока вы можете изучить`) }}
                            <a href="https://informa.gitbook.io/odin_students/" target="_blank" rel="noopener noreferrer">
                                {{ localize('наш блог') }}.
                            </a>
                        </div>
                    </q-card-section>

                    <q-card-actions align="right">
                        <q-btn flat :label="localize('Хорошо')" color="primary" @click="modalInfo = false" />
                    </q-card-actions>
                </q-card>
            </q-dialog>
        </q-page>
    </q-scroll-area>

</template>

<script lang="ts">
    import { defineComponent, Ref, ref, computed, onMounted, nextTick, getCurrentInstance } from 'vue';
    import LocalizationService, { localize } from 'src/services/LocalizationService';
    import { LocaleName } from 'src/types/LocaleName';
    import { LoginForm } from '../types/LoginForm';
    import {
        AuthUrlsResponseModel,
        Result,
        RoutePageNameEnum,
    } from 'src/api/ApiClient';
    import { getServerErrorsModel, handleServerErrors, scrollToFirstInvalidField } from 'src/helpers/serverValidation';
    import { Common } from 'src/helpers/Common';
    import InputPassword from 'components/ui/InputPassword/InputPassword.vue';
    import useValidationRules from 'src/helpers/custom-hooks/useValidationRules';
    import useValidateAllFields from 'src/helpers/custom-hooks/useValidateAllFields';
    import { Notify, QInput } from 'quasar';
    import { useRoute, useRouter } from 'vue-router';
    import { NotifyUtil } from 'src/shims-vue';
    import { useAccountStore } from 'src/store/module-account';
    import { useAuthorizationStore } from 'src/store/module-authorization';
    import { useChatStore } from 'src/store/module-chat';
    import LocaleSettings from 'pages/Login/components/LocaleSettings.vue';

    export default defineComponent({
        name: 'Login',

        components: {
            LocaleSettings,
            InputPassword
        },

        /* eslint-disable-next-line max-lines-per-function  */
        setup() {
            const app = getCurrentInstance();
            const chatStore = useChatStore();
            const accountStore = useAccountStore();
            const authorizationStore = useAuthorizationStore();
            const $router = useRouter();
            const $route = useRoute();

            const emailRef = ref<QInput>();

            // Правила для валидации
            const { isRequired, isRequiredEmail } = useValidationRules();

            const currentTab: Ref<string> = ref('login');

            // Модель для формы логина
            const loginForm: Ref<LoginForm> = ref({
                email: '',
                password: '',
            });
            // Заблокировать кнопку пока вылолнятеся запрос авторизации
            const isDisabledButton: Ref<boolean> = ref(false);
            // Видимость окна проверки об участии в содейтствии занятойит
            const employmentModal: Ref<boolean> = ref(false);
            // Видимость окна с информацией об отправке письма на почту
            const modalInfo: Ref<boolean> = ref(false);
            const yandexAuthUrl: Ref<string> = ref('');
            const googleAuthUrl: Ref<string> = ref('');
            const vkAuthUrl: Ref<string> = ref('');
            const university2035AuthUrl: Ref<string> = ref('');
            const tsuAuthUrl: Ref<string> = ref('');
            const serverErrorsModel: Ref<Record<string, string>> = ref(getServerErrorsModel(loginForm.value));

            // Ссылка на пользовательское соглашение
            const userAgreementRouter = computed((): string => {
                const path = $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.Agreement),
                    query: { type: 'Agreement' },
                });
                return path.fullPath;
            });

            // Ссылка на политику конфиденциальности
            const confirmRouter = computed((): string => {
                const path = $router.resolve({
                    name: Common.getRouteName(RoutePageNameEnum.Agreement),
                    query: { type: 'Confidentiality' },
                });
                return path.fullPath;
            });

            // Enum на регистрацию
            const registerPageName = Common.getRouteName(RoutePageNameEnum.Register);

            // Enum на восстановление пароля
            const forgotPasswordPageName = Common.getRouteName(RoutePageNameEnum.ForgotPassword);

            const queryContainsRedirect = computed((): boolean => {
                return Boolean($route.query.redirect);
            });

            const redirectUrl = computed((): string => {
                return $route.query.redirect ?
                    $route.query.redirect.toString()
                        .trim() : '';
            });

            const queryRedirectsToLogin = computed((): boolean => {
                const loginUrl = Common.getRouteName(RoutePageNameEnum.Login).toLowerCase();

                return redirectUrl.value.toLowerCase().search(loginUrl) !== -1;
            });

            function employmentClose(): void {
                employmentModal.value = false;
                modalInfo.value = true;
            }

            async function login(): Promise<void> {
                const { isInvalid } = useValidateAllFields(app?.refs);

                if (isInvalid) {
                    // Прокрутить страницу до первого не валидного элемента
                    scrollToFirstInvalidField();
                    return;
                }

                isDisabledButton.value = true;

                // Залогиниться в системе и получить токен
                const result: Result = await authorizationStore.login({
                    email: loginForm.value.email,
                    password: loginForm.value.password,
                });

                if (result.isSuccess) {
                    // Получаем информацию о пользователе
                    const accountInfo = await accountStore.loadAccountInfo();

                    if (!accountInfo) {
                        return;
                    }

                    chatStore.loadChatsUnreadedCount();
                    await app?.appContext.config.globalProperties.$fcmService?.sync();

                    // Сохраняем текущую локаль для использования при переадресации
                    const redirectLocale: LocaleName = LocalizationService.getLocaleName();

                    if (queryContainsRedirect.value && !queryRedirectsToLogin.value) {
                        await doRedirect(redirectLocale);
                        return;
                    }

                    await goToDashboard();

                    // Закрываем все оповещения об ошибках
                    (Notify as NotifyUtil).$closeNotify?.forEach((el: any) => {
                        el(void 0);
                    });
                } else {
                    handleServerErrors(result, serverErrorsModel.value);
                }

                isDisabledButton.value = false;
            }

            function register(): void {
                employmentModal.value = true;

                nextTick(() => {
                    currentTab.value = 'login';
                });
            }

            async function doRedirect(currentLocale: LocaleName): Promise<void> {
                const locale = LocalizationService.getLocaleName();
                const urlWithoutLang = redirectUrl.value
                    // Удаляем локаль, если есть
                    .replace(currentLocale, '')
                    // Заменяем возможные дубликаты слэшей одним
                    .replace(/\/+/g, '/');

                await $router.push(urlWithoutLang);

                // Меняем локаль, если она менялась у пользователя
                if (currentLocale !== locale) {
                    location.href = location.href.replace(`/${currentLocale}/`, `/${locale}/`);
                }
            }

            async function goToDashboard(): Promise<void> {
                const dashboard = Common.getRouteName(RoutePageNameEnum.Dashboard);
                await $router.push({ name: dashboard });
            }

            function onChangeEmail(event: string | number | null): void {
                if (!event) {
                    emailRef.value?.resetValidation();
                }
            }

            // Авторизация через соц сети
            async function setAuthUrls(): Promise<void> {
                let redirect: string | null = null;

                if ($route.query.redirect) {
                    redirect = $route.query.redirect.toString();
                }

                // Получить ссылки для регистрации/авторизации через ТГУ, Google и Yandex
                const authUrls: AuthUrlsResponseModel = await Common.GetAuthUrls(redirect);

                yandexAuthUrl.value = authUrls.yandexAuthUrl;
                googleAuthUrl.value = authUrls.googleAuthUrl;
                tsuAuthUrl.value = authUrls.tsuAuthUrl;
                university2035AuthUrl.value = authUrls.university2035AuthUrl;
                vkAuthUrl.value = authUrls.vkAuthUrl;
            }

            onMounted(() => {
                setAuthUrls();
            });

            return {
                emailRef,
                isRequired,
                isRequiredEmail,
                currentTab,
                loginForm,
                isDisabledButton,
                employmentModal,
                modalInfo,
                yandexAuthUrl,
                googleAuthUrl,
                tsuAuthUrl,
                university2035AuthUrl,
                vkAuthUrl,
                serverErrorsModel,
                userAgreementRouter,
                confirmRouter,
                registerPageName,
                forgotPasswordPageName,
                redirectUrl,
                queryRedirectsToLogin,
                getServerErrorsModel,
                handleServerErrors,
                employmentClose,
                login,
                register,
                localize,
                onChangeEmail,
            };
        },
    });

</script>

<style lang="scss">

    .auth-page {
        flex-direction: column;
        flex-wrap: nowrap;

        .q-tabs__content--align-justify {
            .q-tab {
                width: 50%;
            }
        }
    }

    .auth-page__scroll-area {
        height: 100vh;
    }


    @media screen and (max-width: 1000px) {
        .auth-page__scroll-area {
            height: 110vh;
        }
    }

    @media screen and (max-width: 900px) {
        .auth-page__scroll-area {
            height: 120vh;
        }
    }

    @media screen and (max-width: 420px) {
        .auth-page {
            .q-tabs__content--align-justify {
                .q-tab:last-child {
                    width: 53%;
                }
            }
        }
    }
</style>

<style lang="scss" scoped src="./style.scss"></style>
