<template>
    <q-page class="bg-shade-10 auth-page row justify-center items-center">
        <div v-if="isLoading" class="text-center full-height">
            <q-spinner-dots color="primary" size="4em" />
        </div>
        <div v-if="!isLoading" class="column" style="flex: 1;">
            <div class="auth-page__top justify-center row items-end">
                <OdinIcon class="login-odin-icon" aria-hidden="true" />
                <h1 class="odin-title">Odin</h1>
            </div>
            <q-card class="auth-page__form q-pa-md">
                <q-form v-if="!isShowRepeatBlock && !isShowSuccessRepeatBlock">
                    <div class="text-primary text-center q-mb-lg">{{ localize('Введите новый пароль') }}</div>
                    <InputPassword
                        ref="passwordRef"
                        v-model="resetPasswordForm.password"
                        :label="localize('Пароль')"
                        :rules="[isRequired, (val: string | null | undefined) => minLength(val, 10)]"
                        :error="Boolean(serverErrorsModel.password)"
                        :error-message="serverErrorsModel.password"
                        :no-error-icon="true"
                    />
                    <InputPassword
                        ref="confirmPasswordRef"
                        v-model="resetPasswordForm.confirmPassword"
                        :label="localize('Повторите пароль')"
                        :rules="[isRequired, (val: string | null | undefined) => isEqualPassword(val, resetPasswordForm.password)]"
                        :error="Boolean(serverErrorsModel.confirmPassword)"
                        :error-message="serverErrorsModel.confirmPassword"
                        :no-error-icon="true"
                    />
                    <div class="q-mt-md row  items-center justify-end">
                        <q-btn
                            flat
                            color="primary"
                            type="link"
                            class="q-mr-sm"
                            :to="{name: loginPageName}"
                        >
                            {{ localize('Назад') }}
                        </q-btn>
                        <q-btn
                            unelevated
                            color="primary"
                            :label="localize('Сохранить')"
                            @click='resetPassword'
                        />
                    </div>
                </q-form>
                <div v-if="isShowRepeatBlock" class="text-center">
                    <div class="image-block">
                        <img src="~assets/break-chain.svg" class="absolute-center" />
                    </div>
                    <div class="field-description">{{ localize('Ссылка недействительна.') }}</div>
                    <div class="field-description">{{ localize('Для повторного запроса нажмите на кнопку') }}</div>
                    <div class="field-description q-mb-sm">{{ localize('Повторить') }}</div>
                    <vue-smartcaptcha
                        :site-key="getConfig().SmartCaptchaSiteKey"
                        @success="verifyCaptcha"
                        class="q-mb-md"
                    ></vue-smartcaptcha>
                    <q-btn
                        color="primary"
                        :disable="!forgotPasswordForm.captchaToken || isLoadingForgotRequest"
                        :loading="isLoadingForgotRequest"
                        :label="localize('Повторить')"
                        @click="sendRepeat"
                    />
                </div>
                <div v-if="isShowSuccessRepeatBlock" class="text-center">
                    <div class="image-block">
                        <img src="~assets/check.svg" class="absolute-center" />
                    </div>
                    <div class="field-description">{{ localize('Запрос отправлен успешно.') }}</div>
                    <div class="field-description">{{ localize('Вам придёт письмо на электронную почту') }}</div>
                    <div class="field-description">{{ localize('с дальнейшими действиями') }}</div>
                </div>
            </q-card>
        </div>
        <div v-if="!isLoading" class="auth-page__bottom row justify-between full-width q-mt-lg">
            <div class="auth-page__support text-center">
                <div class="auth-page__support_icon">
                    <HelpIcon/>
                </div>
                <a href="mailto:support@odin.study" target="_blank" class="link">support@odin.study</a>
            </div>
            <LocaleSettings />
        </div>
    </q-page>
</template>

<script lang="ts">
    import {
        AccountClient,
        ForgotPasswordRequestModel,
        ResetPasswordRequestModel,
        Result,
        RoutePageNameEnum,
    } from 'src/api/ApiClient';
    import { localize } from 'src/services/LocalizationService';
    import { Notify } from 'quasar';
    import { ApiClientConfig, getApiClientInitialParams } from 'src/api/BaseApiClient';
    import { getServerErrorsModel, handleServerErrors, scrollToFirstInvalidField } from 'src/helpers/serverValidation';
    import { getConfig } from 'src/services/config';
    import { Common } from 'src/helpers/Common';
    import { defineComponent, getCurrentInstance, onMounted, reactive, ref } from 'vue';
    import useValidateAllFields from 'src/helpers/custom-hooks/useValidateAllFields';
    import useValidationRules from 'src/helpers/custom-hooks/useValidationRules';
    import { useRoute, useRouter } from 'vue-router';
    import InputPassword from 'components/ui/InputPassword/InputPassword.vue';
    import VueSmartcaptcha from 'components/VueSmartcaptcha';
    import { NotifyErrors } from 'src/api/ResultOfMethods';
    import LocaleSettings from 'pages/Login/components/LocaleSettings.vue';

    export default defineComponent({
        name: 'ResetPassword',

        components: {
            LocaleSettings,
            VueSmartcaptcha,
            InputPassword
        },

        // eslint-disable-next-line max-lines-per-function
        setup() {
            const app = getCurrentInstance();
            const $router = useRouter();
            const $route = useRoute();

            // Правила валидации
            const { isRequired, minLength, isEqualPassword } = useValidationRules();

            // Модель для смены пароля
            const forgotPasswordForm = reactive<ForgotPasswordRequestModel>({
                email: '',
                captchaToken: ''
            });

            // Модель сброса пароля
            const resetPasswordForm = reactive<ResetPasswordRequestModel>({
                email: '',
                password: '',
                confirmPassword: '',
                code: ''
            });

            // Модель серверных ошибок
            const serverErrorsModel = reactive<Record<string, string>>(getServerErrorsModel(resetPasswordForm));

            // Идет ли получение данных для страницы
            const isLoading = ref<boolean>(true);
            // Показать заглушку если токен неверный
            const isShowRepeatBlock = ref<boolean>(false);
            // Показать блок об отправке повторного сообщения
            const isShowSuccessRepeatBlock = ref<boolean>(false);
            // Выполняется ли запрос на повторную отправку пимьма о восстановлении
            const isLoadingForgotRequest = ref<boolean>(false);

            const loginPageName: string = Common.getRouteName(RoutePageNameEnum.Login);

            async function resetPassword(): Promise<void> {
                const { isInvalid } = useValidateAllFields(app?.refs);

                if (isInvalid) {
                    // Прокрутить страницу до первого не валидного элемента
                    scrollToFirstInvalidField();
                    return;
                }

                isLoading.value = true;

                // Установить новый пароль
                const request: Result = await new AccountClient(getApiClientInitialParams()).resetPassword(resetPasswordForm);
                isLoading.value = false;

                if (!request.isSuccess) {
                    handleServerErrors(request, serverErrorsModel);
                    return;
                }

                Notify.create({
                    type: 'positive',
                    message: localize('Пароль сохранён'),
                });

                $router.push({ name: Common.getRouteName(RoutePageNameEnum.Login) });
            }

            // captcha которую ввел пользователь
            function verifyCaptcha(captcha: string): void {
                forgotPasswordForm.captchaToken = captcha;
            }

            async function sendRepeat(): Promise<void> {
                if (!forgotPasswordForm.captchaToken) {
                    return;
                }

                isLoadingForgotRequest.value = true;

                // Запрос для восстановления забытого пароля
                const request: Result = await new AccountClient(getApiClientInitialParams()).forgotPassword(forgotPasswordForm);

                if (request.isSuccess) {
                    isShowSuccessRepeatBlock.value = true;
                    isShowRepeatBlock.value = false;
                } else {
                    NotifyErrors(request);
                }

                isLoadingForgotRequest.value = false;
            }

            onMounted(async () => {
                // Проверям данные в гет параметрах
                const code = $route.query.code?.toString().trim() ?? '';
                const email = $route.query.email?.toString().trim() ?? '';

                if (!code || !email) {
                    $router.push({ name: Common.getRouteName(RoutePageNameEnum.Login) });

                    Notify.create({
                        type: 'negative',
                        message: localize('Не хватает данных'),
                    });
                    return;
                }

                // Проверка правильности данных
                const result = await new AccountClient(new ApiClientConfig()).checkResetPasswordToken(code, email);

                if (!result.isSuccess) {
                    isShowRepeatBlock.value = true;
                    isLoading.value = false;
                    forgotPasswordForm.email = email;
                    return;
                }

                resetPasswordForm.code = code;
                resetPasswordForm.email = email;

                isLoading.value = false;
            });

            return {
                isRequired,
                minLength,
                isEqualPassword,
                resetPasswordForm,
                forgotPasswordForm,
                serverErrorsModel,
                isLoading,
                isShowRepeatBlock,
                isShowSuccessRepeatBlock,
                isLoadingForgotRequest,
                getConfig,
                loginPageName,
                localize,
                resetPassword,
                sendRepeat,
                verifyCaptcha,
            };
        }

    });
</script>

<style lang="scss" scoped src='../Login/style.scss'></style>
